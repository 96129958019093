.font(@font_name, @file_name, @weight, @style) {
  @font-face {
    font-family: @font_name;
    src: url("/fonts/@{file_name}.woff") format("woff"), url("/fonts/@{file_name}.woff2") format("woff2");
    font-weight: @weight;
    font-style: @style;
    font-display: swap;
  }
}

.font("Montserrat", "Montserrat-Regular", 400, normal);
.font("Montserrat", "Montserrat-Medium", 600, normal);
.font("Montserrat", "Montserrat-Bold", 700, normal);
.font("BarlowC", "BarlowCondensed-Medium", 500, normal);
.font("BarlowC", "BarlowCondensed-Bold", 700, normal);
.font("dripicons-v2", "dripicons-v2", normal, normal);