// РАЗРЕШЕНИЯ
//=============================================================================
@medium: 599.9px;
@xmedium: 767.9px;
@large: 1023.9px;
@xlarge: 1199.9px;
@xxlarge: 1439.9px;

@maxresolution: 4200px;
@resolution: 1280px;
@resolutionText: 860px;

// PAGE PARAMETERS
//=============================================================================
:root {
  --app-height: 100%;
}

.main-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  max-width: @maxresolution;
  width: 100%;
  min-height: var(--app-height);
  margin: auto;
  overflow: hidden;
  //box-shadow: 0 0 50px 0 rgba(@color_b1, .2);

  > .page-content {
    flex: 1 1 auto;
  }

  > .footer {
    order: 99;
  }
}

.resolution-wrap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: @resolution;
  min-width: 320px;
  height: auto;
  min-height: 100%;
  padding-right: @gap;
  padding-left: @gap;

  @media only screen and (max-width: 860px),
  only screen and (max-device-width: 860px) {
    padding-right: @gap;
    padding-left: @gap
  }
}
.resolution-text-wrap {
  display: block;
  left: 0;
  width: 100%;
  max-width: @resolutionText;
  min-width: 320px;
  height: auto;
  min-height: 100%;
  margin: auto;
  padding: 0 20px;

  @media only screen and (max-width: 640px),
  only screen and (max-device-width: 640px) {
    padding: 0 23px;
  }
}

// SVG SPRITES
.svg-sprites {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}