.btn-font{
  font: @f_reg 16px/113% @font;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all .2s linear;
  text-decoration: none;

  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  text-align: left;

  padding: 2px @gap*1.5 0;

  @media only screen and (max-width: 840px),
  only screen and (max-device-width: 840px) {
    font-size: 14px;

    &::before{
      transform: scale(.85) translateY(1px);
    }
  }
}

.btn-form{
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0 20px, 20px 0);
}

// BUTTONS style
//=============================================================================
.btn__theme-1 {
  border: none;
  background: @color_1;
  outline: none;
  border-radius: @br !important;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  text-align: center;
  color: @color_w;
  white-space: nowrap;
  .btn-font;
  .btn-form;

  @media only screen and (max-width: 840px),
  only screen and (max-device-width: 840px) {
    //height: 42px;
  }
}

.btn__theme-2 {
  width: auto;
  min-width: 120px;
  height: @elements_h;
  text-align: center;
  border: 0 solid @color_1;
  //box-shadow: inset 0 0 0 1px rgba(@color_2, 1);
  background: none;
  color: @color_1 !important;
  white-space: nowrap;
  //border-radius: @br;
  background: @color_w;
  position: relative;
  .btn-font;
  .btn-form;

  @media only screen and (max-width: 840px),
  only screen and (max-device-width: 840px) {
    //height: 42px;
  }

  &:active {
    transform: translateY(1px);
  }
}

.btn__theme-3 {
  width: auto;
  height: @elements_h;
  text-align: center;
  border: 2px solid @color_1;
  color: @color_1;
  font-weight: @f_sbold !important;
  background: none;
  white-space: nowrap;
  //border-radius: @br;
  .btn-font;

  @media only screen and (max-width: 840px),
  only screen and (max-device-width: 840px) {
    //height: 42px;
  }

  &:active {
    transform: translateY(1px);
  }

}

.btn__theme-4 {
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  width: auto;
  height: @elements_h;
  padding: 0 @gap*1.5;
  text-align: center;
  text-decoration: none;
  border: 1px solid @color_w;
  color: @color_w;
  background: none;
  white-space: nowrap;
  transition: all .2s linear !important;
  border-radius: @br;

  &:active {
    transform: translateY(1px);
  }

}

.btn__theme-5 {
  width: auto;
  min-width: 120px;
  height: 50px;
  text-align: center;
  border: 1px solid @color_1;
  font-size: 14px;
  //box-shadow: inset 0 0 0 1px rgba(@color_2, 1);
  background: none;
  color: @color_b1 !important;
  white-space: nowrap;
  border-radius: @br;
  background: @color_w;
  position: relative;
  .btn-font;
  padding-bottom: 3px;
  letter-spacing: 0.02em;
  text-transform: inherit;

  @media only screen and (max-width: 840px),
  only screen and (max-device-width: 840px) {
    //height: 42px;
  }

  &:active {
    transform: translateY(1px);
  }
}


[class*="style-btn__"] {
  display: flex;
  display: -ms-flexbox;
  .no_select;

}

.style-btn__1 {
  > * {
    .btn__theme-1;

    svg{
      width: 40px;
      height: 40px;
      fill: @color_w;
      margin-right: @gap /2;
      transition: all .2s linear;

      @media only screen and (max-width: 840px),
      only screen and (max-device-width: 840px) {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.style-btn__2 {
  > * {
    .btn__theme-2;

    svg{
      width: 40px;
      height: 40px;
      fill: @color_1;
      margin-right: @gap /2;
      transition: all .2s linear;

      @media only screen and (max-width: 840px),
      only screen and (max-device-width: 840px) {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.style-btn__3 { // white
  > * {
    .btn__theme-3;

    svg{
      width: 40px;
      height: 40px;
      fill: @color_w;
      margin-right: @gap /2;
      transition: all .2s linear;

      @media only screen and (max-width: 840px),
      only screen and (max-device-width: 840px) {
        width: 30px;
        height: 30px;
      }
    }
  }
}


.style-btn__5 { // white
  > * {
    .btn__theme-5;

    svg{
      width: 36px;
      height: 36px;
      fill: @color_1;
      margin-right: @gap /2;
      transition: all .2s linear;

      @media only screen and (max-width: 840px),
      only screen and (max-device-width: 840px) {
        width: 32px;
        height: 32px;
      }
    }
  }
}


// HOVER not mobile
body:not(.mobile) {
  .style-btn__1 > *:not([disabled]):hover,
  .btn__theme-1:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_2 !important;
    box-shadow: 0 3px 26px -4px rgba(@color_2, .3);
    color: @color_w !important;
  }

  .style-btn__2 > *:not([disabled]):hover,
  .btn__theme-2:not([disabled]):hover {
    cursor: pointer !important;
    border-color: @color_2 !important;
    background: @color_2;
    color: @color_w !important;
    background: @color_2;
  }

  .style-btn__3 > *:not([disabled]):hover,
  .btn__theme-3:not([disabled]):hover {
    cursor: pointer !important;
    color: @color_2 !important;
    border-color: @color_2 !important;
    box-shadow: 0 3px 26px -4px rgba(@color_2, .3);

    svg{
      fill: @color_2 !important;
    }
  }

  .style-btn__4 > *:not([disabled]):hover,
  .btn__theme-4:not([disabled]):hover {
    cursor: pointer !important;
    background: none;
    box-shadow: inset 0 0 0 5px rgba(@color_w, 1);
    color: @color_w !important;
  }

  .style-btn__5 > *:not([disabled]):hover,
  .btn__theme-5:not([disabled]):hover {
    cursor: pointer !important;
    border-color: @color_2 !important;
    background: @color_2;
    color: @color_w !important;
    background: @color_2;

    svg{
      fill: @color_w;
    }
  }
}

.btns_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch;

  > *{
    margin-right: @gap;
    margin-bottom: @gap;
  }
}

.btn_l {
  display: flex;
  justify-content: flex-start !important;
}

.btn_c {
  display: flex;
  justify-content: center !important;
}

.btn_r {
  display: flex;
  justify-content: flex-end !important;

}


// SCROLL UP BUTTON
// =======================================
#go-top {
  position: fixed;
  bottom: 65px;
  right: 10px;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 99;

  @media only screen and (max-width: 480px),
  only screen and (max-device-width: 480px) {
    bottom: 85px;
  }

  > * {
    display: flex;
    text-align: center;
    cursor: pointer;
    width: inherit;
    height: inherit;
    background: @color_1;
    border: 1px solid @color_1;
    collapse: @color_2;
    border-radius: @br;
    transform: scale(0.95);
    text-decoration: none;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    &::after{
      content: '';
      width: 13px;
      height: 13px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(-45deg);
      position: relative;
      top: 3px;

    }

    &:active {
      transform: scale(0.92);
    }
  }
}

.btn-more{
  display: inline-flex;
  min-height: @elements_h /1.2;
  color: @color_1;
  font: @f_reg 20px/110% @font;
  //border-radius: @br;
  user-select: none;
  padding: 10px 0;
  text-decoration: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  transition: all .2s linear;
  background: @color_2;
  position: relative;

  &:focus, &:active{
    //box-shadow: inset 0 0 0 1px @color_1 !important;
    color: @color_1 !important;
    background: @color_2;

    &::after{
      color: @color_1 !important;
    }
  }

  &:hover{
    //box-shadow: inset 0 0 0 100px @color_1;
    color: white;
    cursor: pointer;
    background: @color_2;
    padding-left: 3px;

    &::after{
      color: white;
      margin-left: 13px;
    }
  }
  &:active{
    transform: translateY(2px);
  }

  &::after{
    content: 'V';
    padding-top: 2px;
    position: relative;
    top: 2px;
    .font-icon;
    margin-left: 10px;
  }
}


