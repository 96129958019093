.color-w {
  color: @color_w;
}

.pre-title {
  font: @f_sbold 18px/120% @font !important;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: @color_b3;
}

.title {
  text-align: center;
  margin-bottom: @gap;

  > * {
    font: @f_bold 56px/120% @font_hn !important;
    //text-transform: uppercase;
    letter-spacing: 0.03em;

    @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
      font: @f_bold 42px/120% @font_hn !important;
    }
    @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
      font: @f_bold 36px/120% @font_hn !important;
    }
  }

  b {
    color: @color_1 !important;
  }
}

.title-2 {
  > * {
    font: @f_bold 28px/120% @font !important;
    text-transform: uppercase;
    letter-spacing: 0.04em;

    @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
      font: @f_bold 22px/120% @font !important;
    }
  }

  b {
    color: @color_1 !important;
  }
}

.mw-text {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}

.mw-columns{
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.gap-x2{

  gap:@gap *2;
}

.mb-x3 {
  margin-bottom: @gap * 4;
}

.title-subtitle {
  .title {
    margin-bottom: @gap /2 !important;

    h2 {
      font: @f_bold 56px/115% @font_hn !important;
    }
  }

  .sub-title {
    h5 {
      font: @f_sbold 18px/115% @font !important;
    }
  }
}

// MAIN - WELCOME
.sec-welcome {
  position: relative;
  background: @color_4;
  height:  calc(var(--app-height) - 160px);
  min-height: 860px;
  max-height: 1080px;
  width: 100%;
  display: flex;
  z-index: 3;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  .resolution-wrap{
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    @media only screen and (max-width: 820px), only screen and (max-device-width: 820px) {
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      padding-top: 120px;
    }
  }

  &__content {
    display: flex;
    position: relative;
    z-index: 2;
    width: 100%;

    &__box {
      width: 100%;
      max-width: 620px;
      color: @color_w;

      @media only screen and (max-width: 1160px), only screen and (max-device-width: 1160px) {
        max-width: 420px;
        h1 {
          font: @f_reg 38px/110% 'BarlowC';
          margin-bottom: 8px;

        }
        p {
          font-size: 20px;
        }
      }


      @media only screen and (max-width: 820px), only screen and (max-device-width: 820px) {
        text-align: center;
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;

        .style-btn__1 {
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
        }
      }

      @media only screen and (max-width: 460px), only screen and (max-device-width: 460px) {

        h1 {
          font: @f_reg 30px/110% 'BarlowC';
        }

        p {
          font-size: 16px;
        }

      }

      h1 {
        font: @f_reg 56px/110% 'BarlowC';
        margin-bottom: 8px;

        b {
          display: block;
        }
      }

      p {
        font-size: 26px;
        line-height: 110%;
        margin-bottom: 16px;
      }

    }
  }

  .bg-city {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    //background: url("@{img}bg-main.jpg");
    background-size: cover !important;

    .light{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;

      &::before{
        content: '';
        display: block;
        width: 80%;
        height: 80%;
        background: radial-gradient(circle, @color_4 + 50% 0%, @color_4 100%);
        position: absolute;
        border-radius: 100%;
        filter: blur(200px);
        left: 50%;
        top: 50%;
        transform: translate(-20%, -40%);
      }
    }

    .inclined{
      display: block;
      clip-path: polygon(0 90%, 100% 50%, 100% 100%, 0% 100%);
      background: @color_w;
      width: 100%;
      height: 660px;
      position: absolute;
      bottom: -1px;
      z-index: 3;
    }

    .city{
      width: 75%;
      min-width: 520px;
      max-width: 840px;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(90%, -10%);
      z-index: 50;

      @media only screen and (max-width: 820px),
      only screen and (max-device-width: 820px) {
        top: 100%;
        transform: translate(51%, -100%);
      }


      [class*='wifi__']{
        width: 5%;
        padding-bottom: 6%;
        background: url("@{img}wifi-logo-svgrepo-com.png") 50% 50% no-repeat;
        background-size: 100% auto !important;
        position: absolute;
        z-index: 4;
        animation: city 10s ease-in-out infinite;

        &::before{
          content: '';
          display: block;
          width: 100px;
          height: 100px;
          background: @color_w;
          position: absolute;
          left: 48%;
          transform: translate(-50%, -50%) scale(0);
          top: 50%;
          border-radius: 100%;
          opacity: 0;
        }

        &::after{
          content: '';
          display: block;
          width: 1px;
          height: 80%;
          border-right: 1px dashed @color_w;
          position: absolute;
          left: 48%;
          transform: translateX(-50%);
          top: 50px;
        }
      }

      [class*='__1']{
        top: 10%;
        left: 18%;

        &::before{
          animation: signal 10s linear 3s infinite;
        }
      }
      [class*='__2']{
        top: -2%;
        left: 30%;

        &::before{
          animation: signal 10s linear 1s infinite;
        }
      }
      [class*='__3']{
        top: -10%;
        left: 48%;

        &::before{
          animation: signal 10s linear 5s infinite;
        }
      }
      [class*='__4']{
        top: 7%;
        left: 77%;

        &::before{
          animation: signal 10s linear 1s infinite;
        }
      }
      [class*='__5']{
        top: 45%;
        left: 52%;

        &::before{
          animation: signal 10s linear 8s infinite;
        }
      }
      [class*='__6']{
        top: 25%;
        left: 62%;

        &::before{
          animation: signal 10s linear 3.5s infinite;
        }
      }
      [class*='__7']{
        top: 35%;
        left: 35%;
        &::before{
          animation: signal 10s linear 4s infinite;
        }
      }
      [class*='__8']{
        top: 22%;
        left: 49%;
        &::before{
          animation: signal 10s linear 6.5s infinite;
        }
      }

      > .city-img{
        width: 100%;
        background: url("@{img}city-main.png") 50% 50% no-repeat;
        background-size: 100% auto !important;
        padding-bottom: 70%;
        animation: city 10s linear infinite;
      }

    }
  }
}

@keyframes city {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
@keyframes signal {
  0%, 100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  5% {

    opacity: 0.1;
  }
  10% {

    opacity: 0.2;
  }
  14% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0;
  }
}

.sec-about {
  padding-top: @gap * 4;
  padding-bottom: @gap * 4;

  &__content {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    @media only screen and (max-width: 760px), only screen and (max-device-width: 760px) {
      flex-direction: column;

      &__text {
        order: 2;
      }

      &__illustration {
        margin-bottom: @gap;
      }
    }

    &__text {

    }

    &__illustration {
      svg {
        width: 500px;

        @media only screen and (max-width: 940px), only screen and (max-device-width: 940px) {
          width: 320px;
        }

      }
    }

  }
}

.soc-services {
  background: url("@{img}bg-section-blue.jpg") 0 50%;
  background-size: cover !important;
  padding-top: @gap * 3;
  padding-bottom: @gap * 3;

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 124px;
    justify-content: center; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    gap: @gap *2;

    &__title {
      display: flex;
      width: 100%;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios

      h3 {
        text-align: center;
        font: @f_sbold 32px/125% @font;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      text-align: center;
      flex: 0 1 384px;
      padding-left: 24px;
      padding-right: 24px;

      h3 {
        font: @f_sbold 24px/110% @font;
        margin-bottom: 10px;
        order: 1;

        @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
          font: @f_sbold 20px/110% @font !important;

        }
      }

      p {
        font-size: 16px;
        order: 2;
      }

      .icon svg {
        width: 64px;
        height: 64px;
        order: 0;
      }
    }

    @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
      padding-bottom: 64px;
      //flex-direction: column;
      & > [class*='item-'] {
        width: 100%;
        margin: 0 auto @gap * 2;
        padding-top: 0;
        transform: translateY(0);

      }

      & > [class*='item-']:not(.item-center) {
        width: 50% !important;
      }

      & > .item-center {
        order: 0;
      }
    }

    @media only screen and (max-width: 680px), only screen and (max-device-width: 680px) {
      & > [class*='item-'] {
        max-width: 480px;
      }

      & > [class*='item-']:not(.item-center) {
        width: 100% !important;

      }
    }

  }

}

.sec-how-it-works {
  padding: @gap * 4 0;



  .hiw-list {
    display: flex;
    flex-wrap: wrap;
    gap: @gap * 2;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    //counter-reset: num;
    margin-bottom: @gap * 2;

    &__item {
      padding-right: @gap;
      flex: 0 1 240px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      text-align: center;

      &__icon{
        margin-bottom: @gap;
      }

      &::before {
        //content: counter(num) ' ' !important;
        counter-increment: num !important;
        color: @color_w;
        background: @color_2;
        width: 40px;
        height: 40px;
        .centerxy;
        position: absolute;
        line-height: 17px;
        left: 8px;
        top: 0;
        font-size: 20px;
        font-weight: @f_bold;
        border-radius: 100%;
      }

      h3 {
        font: @f_sbold 18px/110% @font;
        margin-bottom: 8px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}


.section-type-4{
  background: url("@{img}bg-section-blue.jpg") 0 50%;
  background-size: cover !important;
  padding-top: @gap * 3;
  padding-bottom: @gap * 3;

  .footer-block{
    display: flex;
    flex-direction: column;
    gap: @gap ;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    p{
      font-size: 24px;
    }
  }
}

.snake-list{
  display: flex;
  flex-direction: column;
  gap:@gap *2;
  margin-bottom: @gap *4;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  counter-reset: num;

  &__item{
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    gap: @gap * 2;



    &:nth-child(2n+2){
      margin-left: auto;
    }

    &__text{
      max-width: 350px;
      font: @f_reg 24px/130% @font;
    }

    &__icon{
      position: relative;

      &::before {
        content: counter(num) ' ' !important;
        counter-increment: num !important;
        color: @color_w;
        background: @color_2;
        width: 32px;
        height: 32px;
        .centerxy;
        position: absolute;
        line-height: 17px;
        left: 0;
        top: 0;
        font-size: 20px;
        font-weight: @f_bold;
        border-radius: 100%;
      }

      border: 4px solid @color_1;
      width: 120px;
      height: 120px;
      border-radius: 100%;
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios

      &::before{

      }

      svg{
        width: 80px;
        height: 80px;
        min-width: 80px;
      }
    }


  }
}

.sec-hr-block {
  background: url("@{img}bg-section-blue.jpg") 0 50%;
  background-size: cover !important;
  padding-top: @gap * 3;
  padding-bottom: @gap * 3;

  &__content {
    display: flex;

    &__text {
      padding-right: @gap * 2;

    }

    @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
      flex-direction: column;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      text-align: center;

      &__text {
        padding-right: 0;
        margin-bottom: @gap;

      }

    }


  }
}

.sec-consultation {
  padding: @gap * 3 0;
  background: @color_b5;

  .title {
    margin-bottom: @gap * 3;
  }
}

.form-columns {
  display: flex;
  width: calc(100% + @gap * 2) !important;
  left: -@gap;
  margin-bottom: 0 !important;
  //margin-right: -@gap / 2;
  //margin-left: -@gap / 2;
  position: relative;
  flex-wrap: wrap;

  > * {
    flex: 1 1 320px;
    padding-right: @gap;
    padding-left: @gap;
    margin-bottom: 48px;

    > div {
      position: relative;
    }

  }
}

.sec-faq {
  padding: @gap * 3 0;

  .title {
    margin-bottom: @gap * 3;
  }
}

.send-message {
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  flex-direction: column;

  p {
    font-size: 22px;
    margin-bottom: @gap * 2;
  }

  svg {
    width: 220px;
    height: 220px;
    fill: @color_1;
  }
}

@keyframes lightanim {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.23;
  }
}

@keyframes icons {
  0%, 100% {
    transform: translate(-100%, 0);
  }
  50% {
    transform: translate(-100%, -5px);
  }
}

body:not(.mobile) {
  .main-section {
    &__content-box {
      .social-networks {
        a:hover {
          svg {
            stroke: @color_w;

          }
        }
      }

      .click-game {
        .button:hover {
          cursor: pointer;
        }

        .button:active {
          transform: scale(0.96);
        }
      }
    }

    .phones {
      &__wrap {
        a:hover {
          color: @color_w;
        }
      }
    }

    .email {
      a:hover {
        color: @color_w;
      }
    }

  }
}

.email-copy {
  display: flex;
  justify-content: flex-start; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  margin-bottom: 6px;

  a {
    font: @f_sbold 20px/120% @font;
  }

  &__btn {
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    top: 1px;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    margin-left: @gap / 2;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: @color_1;
      border-radius: 100%;
      z-index: 0;
    }

    &.copy {
      &::after {
        animation: copybtn 0.8s linear forwards;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      fill: @color_1;
    }
  }
}

@keyframes copybtn {
  0% {
    width: 1px;
    height: 1px;
    opacity: 1;
  }
  90% {
    width: 40px;
    height: 40px;
    opacity: 0;
  }
  100% {
    width: 1px;
    height: 1px;
    opacity: 0;
  }
}


