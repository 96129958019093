//============================================================================
//+++++++++++++++++++++++++++++++++ HEADER +++++++++++++++++++++++++++++++++++
//============================================================================


// HEADER (big - small)
// ====================================
.header {
  @media (min-width: 960px) and (max-width: 4480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 90;
    width: calc(100% + 2px);
    max-width: @maxresolution;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    background: none;

    .menu-burger {
      display: none;
    }

    .resolution-wrap {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      position: relative;
      transition: all .2s linear;
      padding-left: @gap;
      padding-right: @gap;
    }

    &__main {
      //background: rgba(@color_w, .9);
      //backdrop-filter: blur(13px);
      height: 130px;
      max-height: 130px;
      //border-bottom: 1px solid @color_b5;

      &__nav {
        display: flex;
        margin-left: auto;

        ul {
          display: flex;

          li {

            a {
              position: relative;
              color: @color_w;
              text-decoration: none;
              font: @f_sbold 20px/110% @font;
              display: flex;
              margin-left: @gap * 2;
              padding: @gap 0;
              justify-content: center; // align X
              align-items: center; // align Y
              align-content: center; // align Y ios
              min-height: 60px;

              &::after{
                content: '';
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                transition: all 0.23s linear;
                bottom: 0;
                width: 1%;
                height: 3px;
                background: @color_1;
                opacity: 0;
              }
            }

            &.active a{
              &::after{
                content: '';
                width: 100%;
                opacity: 1;
              }
            }

          }

        }
      }
    }

    .logo {

      transition: all .3s linear;
      position: relative;
      margin-right: @gap *2;

      a {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        transition: all .23s linear;

        svg, img {
          width: 231px;
          height: calc(231px / 3.12);
          transition: all .23s linear;
        }
      }
    }

    // SMALL
    &.header__small {
      background: url("@{img}bg-section-blue.jpg") 0 50%;
      background-size: cover !important;

      .header__main{
        height: 60px;

        &__nav {

          ul {

            li {

              a {
                font: @f_sbold 16px/110% @font;
              }
            }
          }
        }
      }

      .logo {
        a {
          svg, img {
            width: 120px;
            height: calc(120px / 3.12);
            transition: all .23s linear;
          }
        }
      }

    }

  }

  @media (min-width: 280px) and (max-width: 959.98px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 90;
    width: calc(100% + 2px);
    max-width: @maxresolution;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    background: none;

    .menu-burger {
      display: flex;
      width: 46px;
      height: 46px;
      position: absolute;
      right: 10px;
      top: 50%;
      z-index: 3;
      transform: translateY(-50%);

      &:hover {
        cursor: pointer;
      }

      &::before,
      i,
      &::after {
        content: '';
        display: block;
        width: 60%;
        height: 2px;
        background-color: @color_w;
        position: absolute;
        transform-origin: 0 50%;
        left: 50%;
        transform: translateX(-50%);
        transition: all .2s ease-out;
      }

      &::before {
        top: 30%;
      }

      i {
        top: 50%;
      }

      &::after {
        top: 70%;
      }
    }

    .resolution-wrap {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      position: relative;
      transition: all .2s linear;
      padding-left: @gap;
      padding-right: @gap;
    }

    &__main {
      //background: rgba(@color_w, .9);
      //backdrop-filter: blur(13px);
      height: 60px;
      max-height: 60px;
      //border-bottom: 1px solid @color_b5;

      &__nav {
        display: flex;
        min-width: 33.33%;
        margin-left: auto;
        position: absolute;
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
        background: rgba(@color_4, 0.8);
        backdrop-filter: blur(8px);
        top: 0;
        right: 0;
        min-height: var(--app-height);
        padding-top: 80px;
        transition: all 0.2s linear;

        ul {
          display: flex;
          flex-direction: column;

          li {
            padding-left: @gap;
            padding-right: @gap;

            a {
              position: relative;
              color: @color_w;
              text-decoration: none;
              font: @f_sbold 20px/110% @font;
              display: flex;
              padding: @gap;

              &::after{
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -19px;
                transition: all 0.23s linear;
                bottom: 4px;
                width: 10px;
                height: 10px;
                background: @color_1;
                border-radius: 4px;
                scale: 0.5;
                opacity: 0;
              }
            }

            &.active a{
              &::after{
                content: '';
                scale: 1;
                opacity: 1;
              }
            }
          }

        }
      }
    }

    .logo {
      transition: all .3s linear;
      position: relative;
      margin-right: @gap *2;

      a {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        transition: all .23s linear;

        svg, img {
          width: 120px;
          height: calc(120px / 3.12);
          transition: all .23s linear;
        }
      }
    }

    // SMALL
    &.header__small {
      background: url("@{img}bg-section-blue.jpg") 0 50%;
      background-size: cover !important;
    }

    &.menu-active {

      .menu-burger {
        &::before {
          top: 50%;
          left: 20%;
          transform: rotate(40deg);
          width: 20px !important;
        }

        i {
          left: 70%;
          opacity: 0;
        }

        &::after {
          top: 50%;
          left: 20%;
          transform: rotate(-40deg);
          width: 20px !important;
        }
      }

      .header__main__nav{
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

body.main .dynamic-padding {
  @media (min-width: 960px) and (max-width: 4480px) {
    padding-top: 0;
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    padding-top: 0;
  }
}

body:not(.main) .dynamic-padding {
  @media (min-width: 960px) and (max-width: 4480px) {
    padding-top: 170px ;
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    padding-top: 60px;
  }
}

body:not(.mobile) {
  .header {
    @media (min-width: 960px) and (max-width: 4480px) {
      .menu {
        &__item {
          a:not(.active):hover {
            color: @color_2;

            &::after {
              content: '';
              position: absolute;
              z-index: -1;
              display: block;
              width: 120%;
              height: 20px;
              top: -36px;
              left: 50%;
              border-radius: 100%;
              transform: translateX(-50%);
              filter: blur(10px);
              background: @color_2;
              transition: all .2s linear;
            }
          }
        }
      }
    }
  }
}
