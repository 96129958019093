@charset "UTF-8";

@import "/node_modules/aos/dist/aos.css";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: 400;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

button,
input {
  *overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
q:before,
blockquote:after,
q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  padding: 0;
  margin: 0;
}

b,
strong,
b *,
strong * {
  font-weight: 900 !important;
}

i:not(.fas):not(.fa):not(.far):not(.fab):not(.fal):not(.fad):not(.font-icon),
em:not(.fas):not(.fa):not(.far):not(.fab):not(.fal):not(.fad):not(.font-icon) {
  font-style: italic !important;
}

hr {
  display: block;
  width: 100%;
  height: 10px;
  visibility: hidden;
  opacity: 0;
  clear: both;
  float: none;
  margin: 0 !important;
  padding: 0 !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Regular.woff") format("woff"), url("/fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Medium.woff") format("woff"), url("/fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Bold.woff") format("woff"), url("/fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BarlowC";
  src: url("/fonts/BarlowCondensed-Medium.woff") format("woff"), url("/fonts/BarlowCondensed-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BarlowC";
  src: url("/fonts/BarlowCondensed-Bold.woff") format("woff"), url("/fonts/BarlowCondensed-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "dripicons-v2";
  src: url("/fonts/dripicons-v2.woff") format("woff"), url("/fonts/dripicons-v2.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --app-height: 100%;
}

.main-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  max-width: 4200px;
  width: 100%;
  min-height: var(--app-height);
  margin: auto;
  overflow: hidden;
}

.main-container > .page-content {
  flex: 1 1 auto;
}

.main-container > .footer {
  order: 99;
}

.resolution-wrap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1280px;
  min-width: 320px;
  height: auto;
  min-height: 100%;
  padding-right: 16px;
  padding-left: 16px;
}

.resolution-text-wrap {
  display: block;
  left: 0;
  width: 100%;
  max-width: 860px;
  min-width: 320px;
  height: auto;
  min-height: 100%;
  margin: auto;
  padding: 0 20px;
}

.svg-sprites {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

::selection {
  background: rgba(43, 42, 41, 0.8);
  color: #fff !important;
}

::-moz-selection {
  background: rgba(43, 42, 41, 0.8);
  color: #fff !important;
}

.gradient-bg {
  background: #FF5500;
  background: -moz-linear-gradient(31deg, #FF5500 0%, #eb4100 100%);
  background: -webkit-linear-gradient(31deg, #FF5500 0%, #eb4100 100%);
  background: linear-gradient(31deg, #FF5500 0%, #eb4100 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@{@color_1}", endColorstr="@{@color_2}", GradientType=1);
}

.gradient-bg-h {
  background: #FF5500;
  background: -moz-linear-gradient(31deg, #ff6914 0%, #ff5500 100%);
  background: -webkit-linear-gradient(31deg, #ff6914 0%, #ff5500 100%);
  background: linear-gradient(31deg, #ff6914 0%, #ff5500 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FF5500", endColorstr="#eb4100", GradientType=1);
}

html,
body {
  font: 400 17px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  line-height: 130%;
  color: #2B2A29;
}

.ta_l {
  text-align: left !important;
}

.ta_r {
  text-align: right !important;
}

.ta_c {
  text-align: center !important;
}

.no_select,
.no_select * {
  cursor: default;
  user-select: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #F2F3F5;
}

::-webkit-scrollbar-thumb {
  background: #9C9DA3;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #88898f;
}

::-webkit-scrollbar-thumb:active {
  background: #FF5500;
}

select,
a,
button,
*:before,
*:after,
[type="button"],
[type="submit"],
[type="reset"] {
  transition: all 0.25s ease-in-out;
}

a {
  color: #FF5500;
}

.imgfit {
  overflow: hidden;
}

.imgfit img {
  -o-object-fit: cover !important;
  object-fit: cover !important;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover;';
}

.centerxy {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centerx {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centery {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dripicons-"]:before,
[class*=" dripicons-"]:before,
.font-icon {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dripicons-alarm:before {
  content: "\61";
}

.dripicons-align-center:before {
  content: "\62";
}

.dripicons-align-justify:before {
  content: "\63";
}

.dripicons-align-left:before {
  content: "\64";
}

.dripicons-align-right:before {
  content: "\65";
}

.dripicons-anchor:before {
  content: "\66";
}

.dripicons-archive:before {
  content: "\67";
}

.dripicons-arrow-down:before {
  content: "\68";
}

.dripicons-arrow-left:before {
  content: "\69";
}

.dripicons-arrow-right:before {
  content: "\6a";
}

.dripicons-arrow-thin-down:before {
  content: "\6b";
}

.dripicons-arrow-thin-left:before {
  content: "\6c";
}

.dripicons-arrow-thin-right:before {
  content: "\6d";
}

.dripicons-arrow-thin-up:before {
  content: "\6e";
}

.dripicons-arrow-up:before {
  content: "\6f";
}

.dripicons-article:before {
  content: "\70";
}

.dripicons-backspace:before {
  content: "\71";
}

.dripicons-basket:before {
  content: "\72";
}

.dripicons-basketball:before {
  content: "\73";
}

.dripicons-battery-empty:before {
  content: "\74";
}

.dripicons-battery-full:before {
  content: "\75";
}

.dripicons-battery-low:before {
  content: "\76";
}

.dripicons-battery-medium:before {
  content: "\77";
}

.dripicons-bell:before {
  content: "\78";
}

.dripicons-blog:before {
  content: "\79";
}

.dripicons-bluetooth:before {
  content: "\7a";
}

.dripicons-bold:before {
  content: "\41";
}

.dripicons-bookmark:before {
  content: "\42";
}

.dripicons-bookmarks:before {
  content: "\43";
}

.dripicons-box:before {
  content: "\44";
}

.dripicons-briefcase:before {
  content: "\45";
}

.dripicons-brightness-low:before {
  content: "\46";
}

.dripicons-brightness-max:before {
  content: "\47";
}

.dripicons-brightness-medium:before {
  content: "\48";
}

.dripicons-broadcast:before {
  content: "\49";
}

.dripicons-browser:before {
  content: "\4a";
}

.dripicons-browser-upload:before {
  content: "\4b";
}

.dripicons-brush:before {
  content: "\4c";
}

.dripicons-calendar:before {
  content: "\4d";
}

.dripicons-camcorder:before {
  content: "\4e";
}

.dripicons-camera:before {
  content: "\4f";
}

.dripicons-card:before {
  content: "\50";
}

.dripicons-cart:before {
  content: "\51";
}

.dripicons-checklist:before {
  content: "\52";
}

.dripicons-checkmark:before {
  content: "\53";
}

.dripicons-chevron-down:before {
  content: "\54";
}

.dripicons-chevron-left:before {
  content: "\55";
}

.dripicons-chevron-right:before {
  content: "\56";
}

.dripicons-chevron-up:before {
  content: "\57";
}

.dripicons-clipboard:before {
  content: "\58";
}

.dripicons-clock:before {
  content: "\59";
}

.dripicons-clockwise:before {
  content: "\5a";
}

.dripicons-cloud:before {
  content: "\30";
}

.dripicons-cloud-download:before {
  content: "\31";
}

.dripicons-cloud-upload:before {
  content: "\32";
}

.dripicons-code:before {
  content: "\33";
}

.dripicons-contract:before {
  content: "\34";
}

.dripicons-contract-2:before {
  content: "\35";
}

.dripicons-conversation:before {
  content: "\36";
}

.dripicons-copy:before {
  content: "\37";
}

.dripicons-crop:before {
  content: "\38";
}

.dripicons-cross:before {
  content: "\39";
}

.dripicons-crosshair:before {
  content: "\21";
}

.dripicons-cutlery:before {
  content: "\22";
}

.dripicons-device-desktop:before {
  content: "\23";
}

.dripicons-device-mobile:before {
  content: "\24";
}

.dripicons-device-tablet:before {
  content: "\25";
}

.dripicons-direction:before {
  content: "\26";
}

.dripicons-disc:before {
  content: "\27";
}

.dripicons-document:before {
  content: "\28";
}

.dripicons-document-delete:before {
  content: "\29";
}

.dripicons-document-edit:before {
  content: "\2a";
}

.dripicons-document-new:before {
  content: "\2b";
}

.dripicons-document-remove:before {
  content: "\2c";
}

.dripicons-dot:before {
  content: "\2d";
}

.dripicons-dots-2:before {
  content: "\2e";
}

.dripicons-dots-3:before {
  content: "\2f";
}

.dripicons-download:before {
  content: "\3a";
}

.dripicons-duplicate:before {
  content: "\3b";
}

.dripicons-enter:before {
  content: "\3c";
}

.dripicons-exit:before {
  content: "\3d";
}

.dripicons-expand:before {
  content: "\3e";
}

.dripicons-expand-2:before {
  content: "\3f";
}

.dripicons-experiment:before {
  content: "\40";
}

.dripicons-export:before {
  content: "\5b";
}

.dripicons-feed:before {
  content: "\5d";
}

.dripicons-flag:before {
  content: "\5e";
}

.dripicons-flashlight:before {
  content: "\5f";
}

.dripicons-folder:before {
  content: "\60";
}

.dripicons-folder-open:before {
  content: "\7b";
}

.dripicons-forward:before {
  content: "\7c";
}

.dripicons-gaming:before {
  content: "\7d";
}

.dripicons-gear:before {
  content: "\7e";
}

.dripicons-graduation:before {
  content: "\5c";
}

.dripicons-graph-bar:before {
  content: "\e000";
}

.dripicons-graph-line:before {
  content: "\e001";
}

.dripicons-graph-pie:before {
  content: "\e002";
}

.dripicons-headset:before {
  content: "\e003";
}

.dripicons-heart:before {
  content: "\e004";
}

.dripicons-help:before {
  content: "\e005";
}

.dripicons-home:before {
  content: "\e006";
}

.dripicons-hourglass:before {
  content: "\e007";
}

.dripicons-inbox:before {
  content: "\e008";
}

.dripicons-information:before {
  content: "\e009";
}

.dripicons-italic:before {
  content: "\e00a";
}

.dripicons-jewel:before {
  content: "\e00b";
}

.dripicons-lifting:before {
  content: "\e00c";
}

.dripicons-lightbulb:before {
  content: "\e00d";
}

.dripicons-link:before {
  content: "\e00e";
}

.dripicons-link-broken:before {
  content: "\e00f";
}

.dripicons-list:before {
  content: "\e010";
}

.dripicons-loading:before {
  content: "\e011";
}

.dripicons-location:before {
  content: "\e012";
}

.dripicons-lock:before {
  content: "\e013";
}

.dripicons-lock-open:before {
  content: "\e014";
}

.dripicons-mail:before {
  content: "\e015";
}

.dripicons-map:before {
  content: "\e016";
}

.dripicons-media-loop:before {
  content: "\e017";
}

.dripicons-media-next:before {
  content: "\e018";
}

.dripicons-media-pause:before {
  content: "\e019";
}

.dripicons-media-play:before {
  content: "\e01a";
}

.dripicons-media-previous:before {
  content: "\e01b";
}

.dripicons-media-record:before {
  content: "\e01c";
}

.dripicons-media-shuffle:before {
  content: "\e01d";
}

.dripicons-media-stop:before {
  content: "\e01e";
}

.dripicons-medical:before {
  content: "\e01f";
}

.dripicons-menu:before {
  content: "\e020";
}

.dripicons-message:before {
  content: "\e021";
}

.dripicons-meter:before {
  content: "\e022";
}

.dripicons-microphone:before {
  content: "\e023";
}

.dripicons-minus:before {
  content: "\e024";
}

.dripicons-monitor:before {
  content: "\e025";
}

.dripicons-move:before {
  content: "\e026";
}

.dripicons-music:before {
  content: "\e027";
}

.dripicons-network-1:before {
  content: "\e028";
}

.dripicons-network-2:before {
  content: "\e029";
}

.dripicons-network-3:before {
  content: "\e02a";
}

.dripicons-network-4:before {
  content: "\e02b";
}

.dripicons-network-5:before {
  content: "\e02c";
}

.dripicons-pamphlet:before {
  content: "\e02d";
}

.dripicons-paperclip:before {
  content: "\e02e";
}

.dripicons-pencil:before {
  content: "\e02f";
}

.dripicons-phone:before {
  content: "\e030";
}

.dripicons-photo:before {
  content: "\e031";
}

.dripicons-photo-group:before {
  content: "\e032";
}

.dripicons-pill:before {
  content: "\e033";
}

.dripicons-pin:before {
  content: "\e034";
}

.dripicons-plus:before {
  content: "\e035";
}

.dripicons-power:before {
  content: "\e036";
}

.dripicons-preview:before {
  content: "\e037";
}

.dripicons-print:before {
  content: "\e038";
}

.dripicons-pulse:before {
  content: "\e039";
}

.dripicons-question:before {
  content: "\e03a";
}

.dripicons-reply:before {
  content: "\e03b";
}

.dripicons-reply-all:before {
  content: "\e03c";
}

.dripicons-return:before {
  content: "\e03d";
}

.dripicons-retweet:before {
  content: "\e03e";
}

.dripicons-rocket:before {
  content: "\e03f";
}

.dripicons-scale:before {
  content: "\e040";
}

.dripicons-search:before {
  content: "\e041";
}

.dripicons-shopping-bag:before {
  content: "\e042";
}

.dripicons-skip:before {
  content: "\e043";
}

.dripicons-stack:before {
  content: "\e044";
}

.dripicons-star:before {
  content: "\e045";
}

.dripicons-stopwatch:before {
  content: "\e046";
}

.dripicons-store:before {
  content: "\e047";
}

.dripicons-suitcase:before {
  content: "\e048";
}

.dripicons-swap:before {
  content: "\e049";
}

.dripicons-tag:before {
  content: "\e04a";
}

.dripicons-tag-delete:before {
  content: "\e04b";
}

.dripicons-tags:before {
  content: "\e04c";
}

.dripicons-thumbs-down:before {
  content: "\e04d";
}

.dripicons-thumbs-up:before {
  content: "\e04e";
}

.dripicons-ticket:before {
  content: "\e04f";
}

.dripicons-time-reverse:before {
  content: "\e050";
}

.dripicons-to-do:before {
  content: "\e051";
}

.dripicons-toggles:before {
  content: "\e052";
}

.dripicons-trash:before {
  content: "\e053";
}

.dripicons-trophy:before {
  content: "\e054";
}

.dripicons-upload:before {
  content: "\e055";
}

.dripicons-user:before {
  content: "\e056";
}

.dripicons-user-group:before {
  content: "\e057";
}

.dripicons-user-id:before {
  content: "\e058";
}

.dripicons-vibrate:before {
  content: "\e059";
}

.dripicons-view-apps:before {
  content: "\e05a";
}

.dripicons-view-list:before {
  content: "\e05b";
}

.dripicons-view-list-large:before {
  content: "\e05c";
}

.dripicons-view-thumb:before {
  content: "\e05d";
}

.dripicons-volume-full:before {
  content: "\e05e";
}

.dripicons-volume-low:before {
  content: "\e05f";
}

.dripicons-volume-medium:before {
  content: "\e060";
}

.dripicons-volume-off:before {
  content: "\e061";
}

.dripicons-wallet:before {
  content: "\e062";
}

.dripicons-warning:before {
  content: "\e063";
}

.dripicons-web:before {
  content: "\e064";
}

.dripicons-weight:before {
  content: "\e065";
}

.dripicons-wifi:before {
  content: "\e066";
}

.dripicons-wrong:before {
  content: "\e067";
}

.dripicons-zoom-in:before {
  content: "\e068";
}

.dripicons-zoom-out:before {
  content: "\e069";
}

.caps {
  text-transform: uppercase;
}

.text-box > br,
.text-wrap > br {
  position: relative;
  width: 100%;
  display: block;
}

.text-box .row,
.text-wrap .row,
.text-box .logos,
.text-wrap .logos {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.text-box .row[data-columns='1'] > .column,
.text-wrap .row[data-columns='1'] > .column,
.text-box .logos[data-columns='1'] > .column,
.text-wrap .logos[data-columns='1'] > .column {
  width: 100% !important;
}

.text-box .row[data-columns='2'] > .column,
.text-wrap .row[data-columns='2'] > .column,
.text-box .logos[data-columns='2'] > .column,
.text-wrap .logos[data-columns='2'] > .column {
  width: 50% !important;
}

.text-box .row[data-columns='3'] > .column,
.text-wrap .row[data-columns='3'] > .column,
.text-box .logos[data-columns='3'] > .column,
.text-wrap .logos[data-columns='3'] > .column {
  width: 33.33% !important;
}

.text-box .row[data-columns='4'] > .column,
.text-wrap .row[data-columns='4'] > .column,
.text-box .logos[data-columns='4'] > .column,
.text-wrap .logos[data-columns='4'] > .column {
  width: 25% !important;
}

.text-box .row,
.text-wrap .row {
  margin-bottom: 16px;
}

.text-box .row > .column,
.text-wrap .row > .column {
  padding-left: 8px !important;
  padding-right: 8px !important;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 16px;
}

.text-box .row > .column .img-wrap,
.text-wrap .row > .column .img-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  padding-bottom: 60%;
  width: 100%;
  height: auto;
}

.text-box .row > .column .img-wrap img,
.text-wrap .row > .column .img-wrap img {
  position: absolute;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  left: 0;
  top: 0;
}

.text-box .logos,
.text-wrap .logos {
  margin-bottom: 32px;
}

.text-box .logos .img-wrap,
.text-wrap .logos .img-wrap {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: none !important;
}

.text-box .logos .img-wrap img,
.text-wrap .logos .img-wrap img {
  position: relative;
  width: auto !important;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.text-box .my-img,
.text-wrap .my-img {
  padding: 0;
}

.text-box .video-wrap,
.text-wrap .video-wrap {
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.text-box .video-wrap iframe,
.text-wrap .video-wrap iframe {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.text-box .title > *,
.text-wrap .title > * {
  font: 400 58px/98% 'BarlowC', Helvetica, Arial, sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.text-box h1,
.text-wrap h1 {
  font: 400 58px/98% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.14px;
  position: relative;
  margin-bottom: 10px;
}

.text-box h2,
.text-wrap h2 {
  font: 600 42px/98% 'Montserrat', Helvetica, Arial, sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.text-box h3,
.text-wrap h3 {
  font: 600 32px/105% 'Montserrat', Helvetica, Arial, sans-serif !important;
  position: relative;
  margin-bottom: 10px;
}

.text-box h3 a,
.text-wrap h3 a {
  font: 600 22px/120% 'Montserrat', Helvetica, Arial, sans-serif !important;
  color: #2B2A29;
}

.text-box h3 a:hover,
.text-wrap h3 a:hover {
  color: #eb4100;
}

.text-box h4,
.text-wrap h4 {
  font: 600 19px/116% 'Montserrat', Helvetica, Arial, sans-serif !important;
  position: relative;
  margin-bottom: 10px;
}

.text-box h4 a,
.text-wrap h4 a {
  font: 600 19px/116% 'Montserrat', Helvetica, Arial, sans-serif !important;
  color: #eb4100;
}

.text-box h4 a:hover,
.text-wrap h4 a:hover {
  color: #ff5500;
}

.text-box .date,
.text-wrap .date {
  color: #626268;
  margin-bottom: 16px;
}

.text-box .date::before,
.text-wrap .date::before {
  content: '\4d';
  color: #9C9DA3;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-box .sub-title > *,
.text-wrap .sub-title > * {
  font: 600 28px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.text-box .sub-sub-title,
.text-wrap .sub-sub-title {
  font: 400 25px/140% 'Montserrat', Helvetica, Arial, sans-serif;
}

.text-box p > img,
.text-wrap p > img {
  position: relative;
  max-width: 100% !important;
}

.text-box p,
.text-wrap p {
  font: 400 17px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  margin-bottom: 16px;
}

.text-box .big-img,
.text-wrap .big-img {
  max-width: 100%;
  display: flex;
  border-radius: 0;
  box-shadow: none;
  position: relative;
  margin-bottom: 16px;
}

.text-box .big-img img,
.text-wrap .big-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  font-family: 'object-fit: cover;';
}

.text-box [class*='prev-img'],
.text-wrap [class*='prev-img'] {
  width: 33.33%;
  display: flex;
}

.text-box [class*='prev-img'] > div,
.text-wrap [class*='prev-img'] > div {
  overflow: hidden;
  display: flex;
  flex: 1 0 120px;
  width: 100%;
  padding-bottom: 66.25%;
  position: relative;
  margin-bottom: 16px;
  border-radius: 0;
  box-shadow: none;
}

.text-box [class*='prev-img'] > div img,
.text-wrap [class*='prev-img'] > div img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
}

.text-box [class*='prev-img'].prev-img__left,
.text-wrap [class*='prev-img'].prev-img__left {
  float: left;
  margin-right: 16px;
}

.text-box [class*='prev-img'].prev-img__right,
.text-wrap [class*='prev-img'].prev-img__right {
  float: right;
  margin-left: 16px;
}

.text-box .quote,
.text-wrap .quote,
.text-box .quote_c,
.text-wrap .quote_c,
.text-box blockquote,
.text-wrap blockquote {
  position: relative;
  margin-bottom: 32px;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  border: 2px solid #F2F3F5;
  border-radius: 0;
  padding: 20px 20px 20px 60px;
  box-shadow: none;
}

.text-box .quote_c,
.text-wrap .quote_c {
  text-align: center;
  padding-left: 20px !important;
}

.text-box .quote::before,
.text-wrap .quote::before,
.text-box blockquote::before,
.text-wrap blockquote::before {
  content: "";
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  background: #F04400;
  border-radius: 0 0 0 0;
}

.text-box .quote::after,
.text-wrap .quote::after,
.text-box blockquote::after,
.text-wrap blockquote::after {
  content: "\49";
  width: 40px;
  height: 40px;
  position: absolute;
  color: #ffffff;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 23px;
  top: 20px;
  left: 8px;
  z-index: 2;
  display: block;
}

.text-box ul:not(.todo-list):not(.numeric-list),
.text-wrap ul:not(.todo-list):not(.numeric-list) {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto 32px auto;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li,
.text-wrap ul:not(.todo-list):not(.numeric-list) > li {
  position: relative;
  list-style: none;
  min-height: 25px;
  padding-left: 18px;
  color: #2B2A29;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li:not(:last-of-type),
.text-wrap ul:not(.todo-list):not(.numeric-list) > li:not(:last-of-type) {
  margin-bottom: 16px;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li:before,
.text-wrap ul:not(.todo-list):not(.numeric-list) > li:before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 2px;
  top: 7px;
  z-index: 3;
  background: rgba(235, 65, 0, 0.7);
  border-radius: 100%;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li > *,
.text-wrap ul:not(.todo-list):not(.numeric-list) > li > * {
  margin: 10px auto 5px;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li > * > li:before,
.text-wrap ul:not(.todo-list):not(.numeric-list) > li > * > li:before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 2px;
  top: 7px;
  z-index: 3;
  background: #9C9DA3;
  border-radius: 100%;
}

.text-box ul.todo-list,
.text-wrap ul.todo-list {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto 32px auto;
}

.text-box ul.todo-list > li,
.text-wrap ul.todo-list > li {
  position: relative;
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
  padding-left: 18px;
}

.text-box ul.todo-list > li label,
.text-wrap ul.todo-list > li label {
  position: absolute;
  left: 0;
  top: -2px;
  margin-right: 10px;
}

.text-box ol,
.text-wrap ol {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto 32px auto;
}

.text-box ol > li,
.text-wrap ol > li {
  position: relative;
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
  padding-left: 30px;
  color: #2B2A29;
}

.text-box ol > li::before,
.text-wrap ol > li::before {
  content: counter(num) ' ' !important;
  counter-increment: num !important;
  color: #fff;
  background: #eb4100;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  line-height: 17px;
  left: 0;
  top: 0px;
  font-size: 12px;
  font-weight: 900;
  border-radius: 0;
}

.text-box .action-color,
.text-wrap .action-color {
  color: #FF5500;
}

.text-box .anchors a,
.text-wrap .anchors a {
  text-decoration: none;
  color: #E1E2E7;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}

.text-box .anchors a:hover,
.text-wrap .anchors a:hover {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.8);
}

.text-box .numeric-hn,
.text-wrap .numeric-hn {
  position: relative;
  display: inline-block;
  margin-bottom: 16px;
  padding-left: 60px;
}

.text-box .numeric-hn > span,
.text-wrap .numeric-hn > span {
  display: flex;
  align-self: stretch;
  min-height: 100%;
  align-content: center;
  align-items: center;
  font: 600 32px/120% 'Montserrat', Helvetica, Arial, sans-serif;
}

.text-box .numeric-hn::before,
.text-wrap .numeric-hn::before {
  content: attr(data-numeric);
  text-align: left;
  margin-right: 16px;
  color: #eb4100;
  display: flex;
  flex: 0 0 46px;
  width: 46px;
  min-width: 46px;
  height: 46px;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  line-height: 17px;
  left: -4px;
  top: -10px;
  font-size: 20px;
  font-weight: 900;
  border-radius: 0;
}

.text-box .numeric-sub,
.text-wrap .numeric-sub {
  position: relative;
  display: block;
  margin-bottom: 16px;
  padding-left: 80px;
}

.text-box .numeric-sub::before,
.text-wrap .numeric-sub::before {
  content: attr(data-numeric);
  text-align: left;
  margin-right: 16px;
  color: #2B2A29;
  display: flex;
  flex: 0 0 46px;
  width: 62px;
  min-width: 46px;
  height: 46px;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  line-height: 17px;
  left: 5px;
  top: -10px;
  font-size: 16px;
  font-weight: 900;
  border-radius: 0;
}

.text-box .numeric-list > li,
.text-wrap .numeric-list > li {
  padding-left: 65px;
  margin-bottom: 32px;
  position: relative;
}

.text-box .numeric-list > li > ul.numeric-list > li,
.text-wrap .numeric-list > li > ul.numeric-list > li {
  font: 400 15px/125% 'Montserrat', Helvetica, Arial, sans-serif !important;
  color: #9C9DA3;
}

.text-box .numeric-list > li > ul.numeric-list > li .numeric-list__number,
.text-wrap .numeric-list > li > ul.numeric-list > li .numeric-list__number {
  font-size: 14px !important;
}

.text-box .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li,
.text-wrap .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li {
  padding-left: 90px !important;
}

.text-box .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li .numeric-list__number,
.text-wrap .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li .numeric-list__number {
  font-size: 13px !important;
}

.text-box .numeric-list__number,
.text-wrap .numeric-list__number {
  margin-right: 16px;
  background: #3f3e3d;
  display: inline-flex;
  width: auto;
  height: auto;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 8px 11px 6px 13px;
  left: 0;
  top: -3px;
  color: #9C9DA3;
  font-size: 16px;
  line-height: 16px !important;
  font-weight: 600;
  border-radius: 0;
}

.text-box .table-scroll,
.text-wrap .table-scroll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.text-box .table-theme-1,
.text-wrap .table-theme-1 {
  width: 100%;
  box-shadow: 0 5px 6px -2px rgba(0, 0, 0, 0.2);
}

.text-box .table-theme-1 thead,
.text-wrap .table-theme-1 thead {
  background: #FF5500;
}

.text-box .table-theme-1 thead th,
.text-wrap .table-theme-1 thead th {
  color: #fff;
  text-align: left;
}

.text-box .table-theme-1 thead th:last-child,
.text-wrap .table-theme-1 thead th:last-child {
  border-radius: 0 8px 0 0;
}

.text-box .table-theme-1 thead th:first-child,
.text-wrap .table-theme-1 thead th:first-child {
  border-radius: 8px 0 0 0;
}

.text-box .table-theme-1 tbody tr td,
.text-wrap .table-theme-1 tbody tr td {
  text-align: left;
  border-right: 1px solid #F2F3F5;
  border-bottom: 1px solid #F2F3F5;
}

.text-box .table-theme-1 tbody tr td:first-child,
.text-wrap .table-theme-1 tbody tr td:first-child {
  border-left: 1px solid #F2F3F5;
}

.text-box .table-theme-1 tbody tr:last-of-type td:last-of-type,
.text-wrap .table-theme-1 tbody tr:last-of-type td:last-of-type {
  border-radius: 0 0 8px 0;
}

.text-box .table-theme-1 tbody tr:last-of-type td:first-child,
.text-wrap .table-theme-1 tbody tr:last-of-type td:first-child {
  border-radius: 0 0 0 8px;
}

.text-box .table-theme-1 th,
.text-wrap .table-theme-1 th,
.text-box .table-theme-1 td,
.text-wrap .table-theme-1 td {
  padding: 8px 10px;
}

.text-box table.adaptive-table,
.text-wrap table.adaptive-table {
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.text-box table.adaptive-table thead,
.text-wrap table.adaptive-table thead {
  display: none;
}

.text-box table.adaptive-table tr,
.text-wrap table.adaptive-table tr {
  display: grid;
  border: 1px solid #e8e9eb;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  background: #fff;
  margin-bottom: 16px;
  border-radius: 16px;
  box-shadow: none;
  padding: 6px 8px 8px;
}

.text-box table.adaptive-table tr > td,
.text-wrap table.adaptive-table tr > td {
  font-size: 14px;
  display: inline-flex;
  flex-direction: column;
  padding: 6px 16px 16px;
  text-align: left;
  position: relative;
}

.text-box table.adaptive-table tr > td:nth-child(1),
.text-wrap table.adaptive-table tr > td:nth-child(1) {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 2;
}

.text-box table.adaptive-table tr > td:nth-child(2),
.text-wrap table.adaptive-table tr > td:nth-child(2) {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
}

.text-box table.adaptive-table tr > td:nth-child(3),
.text-wrap table.adaptive-table tr > td:nth-child(3) {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-row-end: 4;
  grid-column-end: 2;
}

.text-box table.adaptive-table tr > td:nth-child(4),
.text-wrap table.adaptive-table tr > td:nth-child(4) {
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-column-end: 3;
}

.text-box table.adaptive-table tr > td:nth-child(5),
.text-wrap table.adaptive-table tr > td:nth-child(5) {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 3;
}

.text-box table.adaptive-table tr > td:nth-child(6),
.text-wrap table.adaptive-table tr > td:nth-child(6) {
  grid-row-start: 3;
  grid-column-start: 2;
  grid-row-end: 4;
  grid-column-end: 3;
}

.text-box table.adaptive-table tr > td:nth-child(7),
.text-wrap table.adaptive-table tr > td:nth-child(7) {
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 4;
  grid-column-end: 4;
}

.text-box table.adaptive-table tr > td::before,
.text-wrap table.adaptive-table tr > td::before {
  content: attr(data-thn);
  display: flex;
  width: calc(100% + 26px);
  font: 600 16px/110% 'Montserrat', Helvetica, Arial, sans-serif;
  padding: 10px 13px;
  margin-bottom: 4px;
  background: #F2F3F5;
  border-radius: 8px;
  left: -13px;
  position: relative;
}

.text-box table.adaptive-table tr > td::after,
.text-wrap table.adaptive-table tr > td::after {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

[class*='list_v'] {
  margin: 0 auto 20px;
}

[class*='list_v'] li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 25px;
}

.list_v1 li:before {
  content: '\53';
  width: 20px;
  height: 20px;
  position: absolute;
  left: -3px;
  top: 0;
  font-family: "dripicons-v2" !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 17px;
  text-rendering: auto;
  font-style: normal !important;
  color: #eb4100;
}

.list_v2 li:before {
  content: "\39";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 4px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 16px;
  text-rendering: auto;
  color: #103060 !important;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list_v3 {
  list-style-type: none;
  counter-reset: num;
  margin-bottom: 40px;
}

.list_v3 > li {
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
}

.list_v3 > li::before {
  content: counter(num) ' ' !important;
  counter-increment: num !important;
  color: #fff;
  background: #FF5500;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  line-height: 17px;
  left: -8px;
  top: 0;
  font-size: 13px;
  font-weight: 600;
  border-radius: 0;
}

.list_v4 li {
  padding: 0 0 14px 0;
  border-bottom: 1px solid #F2F3F5;
}

.list_v5 > li {
  margin-bottom: 10px;
}

.list_v5 > li::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 6px;
  top: 8px;
  border-radius: 100%;
  z-index: 3;
  background: #eb4100;
}

.list_v5 > li > ul {
  margin: 13px auto 0;
}

.list_v5 > li > ul > li {
  width: 100%;
}

.list_v5 > li > ul > li::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 6px;
  top: 8px;
  border-radius: 100%;
  z-index: 3;
  background: #F2F3F5;
}

.list_v6 > li {
  margin-bottom: 8px;
  padding-left: 13px;
}

.list_v6 > li::before {
  content: '\e01c';
  width: 10px;
  height: 10px;
  position: absolute;
  left: -3px;
  top: 3px;
  font-size: 6px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #FF5500;
}

.list_v6 > li > ul {
  margin: 13px auto 0;
}

.list_v6 > li > ul > li {
  width: 100%;
}

.list_v6 > li > ul > li::before {
  content: '\e01c';
  background-position: 50% -360px;
  width: 10px;
  height: 10px;
  position: absolute;
  left: -3px;
  top: 1px;
  font-size: 9px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #F2F3F5;
}

.accordion {
  display: flex;
  flex-direction: column;
}

.accordion__item {
  margin-bottom: 16px;
}

.accordion__item__header {
  display: flex;
  min-height: 50px;
  align-items: center;
  align-content: center;
  position: relative;
}

.accordion__item__header::after {
  content: "\e035";
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0;
  top: 14px;
  font-size: 22px;
  color: rgba(156, 157, 163, 0.5);
}

.accordion__item__content {
  transition: all 0.1s ease-in-out;
  transform-origin: 50% 0;
  overflow: hidden;
}

.accordion__item:not(.active) .accordion__item__content {
  max-height: 0;
  transform: scaleY(0.3);
  opacity: 0;
  visibility: hidden;
}

.accordion__item.active .accordion__item__header::after {
  content: "\e024";
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ff5500;
}

.accordion__item.active .accordion__item__content {
  max-height: 1000px;
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}

.accordion__theme-1 .accordion__item {
  padding: 16px 32px;
  border-radius: 0;
  background: #fff;
  box-shadow: inset 0 0 0 2px #f2f3f5;
}

.accordion__theme-1 .accordion__item .accordion__item__header {
  padding-right: 40px;
  cursor: pointer;
}

.accordion__theme-1 .accordion__item .accordion__item__header h3 {
  margin-bottom: 2px;
  font: 600 22px/120% 'Montserrat', Helvetica, Arial, sans-serif !important;
}

.accordion__theme-1 .accordion__item .accordion__item__content p {
  margin-bottom: 16px;
}

.accordion__theme-1 .accordion__item.active {
  box-shadow: inset 0 0 0 2px #ff5500;
}

.btn-font {
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
}

.btn-form {
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0 20px, 20px 0);
}

.btn__theme-1 {
  border: none;
  background: #FF5500;
  outline: none;
  border-radius: 0 !important;
  width: auto;
  min-width: 120px;
  height: 54px;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0 20px, 20px 0);
}

.btn__theme-2 {
  width: auto;
  min-width: 120px;
  height: 54px;
  text-align: center;
  border: 0 solid #FF5500;
  background: none;
  color: #FF5500 !important;
  white-space: nowrap;
  background: #fff;
  position: relative;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0 20px, 20px 0);
}

.btn__theme-2:active {
  transform: translateY(1px);
}

.btn__theme-3 {
  width: auto;
  height: 54px;
  text-align: center;
  border: 2px solid #FF5500;
  color: #FF5500;
  font-weight: 600 !important;
  background: none;
  white-space: nowrap;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
}

.btn__theme-3:active {
  transform: translateY(1px);
}

.btn__theme-4 {
  justify-content: center;
  align-items: center;
  align-content: center;
  width: auto;
  height: 54px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #fff;
  color: #fff;
  background: none;
  white-space: nowrap;
  transition: all 0.2s linear !important;
  border-radius: 0;
}

.btn__theme-4:active {
  transform: translateY(1px);
}

.btn__theme-5 {
  width: auto;
  min-width: 120px;
  height: 50px;
  text-align: center;
  border: 1px solid #FF5500;
  font-size: 14px;
  background: none;
  color: #2B2A29 !important;
  white-space: nowrap;
  border-radius: 0;
  background: #fff;
  position: relative;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
  padding-bottom: 3px;
  letter-spacing: 0.02em;
  text-transform: inherit;
}

.btn__theme-5:active {
  transform: translateY(1px);
}

[class*="style-btn__"] {
  display: flex;
  display: -ms-flexbox;
  cursor: default;
  user-select: none !important;
}

.style-btn__1 > * {
  border: none;
  background: #FF5500;
  outline: none;
  border-radius: 0 !important;
  width: auto;
  min-width: 120px;
  height: 54px;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0 20px, 20px 0);
}

.style-btn__1 > * svg {
  width: 40px;
  height: 40px;
  fill: #fff;
  margin-right: 8px;
  transition: all 0.2s linear;
}

.style-btn__2 > * {
  width: auto;
  min-width: 120px;
  height: 54px;
  text-align: center;
  border: 0 solid #FF5500;
  background: none;
  color: #FF5500 !important;
  white-space: nowrap;
  background: #fff;
  position: relative;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0 20px, 20px 0);
}

.style-btn__2 > *:active {
  transform: translateY(1px);
}

.style-btn__2 > * svg {
  width: 40px;
  height: 40px;
  fill: #FF5500;
  margin-right: 8px;
  transition: all 0.2s linear;
}

.style-btn__3 > * {
  width: auto;
  height: 54px;
  text-align: center;
  border: 2px solid #FF5500;
  color: #FF5500;
  font-weight: 600 !important;
  background: none;
  white-space: nowrap;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
}

.style-btn__3 > *:active {
  transform: translateY(1px);
}

.style-btn__3 > * svg {
  width: 40px;
  height: 40px;
  fill: #fff;
  margin-right: 8px;
  transition: all 0.2s linear;
}

.style-btn__5 > * {
  width: auto;
  min-width: 120px;
  height: 50px;
  text-align: center;
  border: 1px solid #FF5500;
  font-size: 14px;
  background: none;
  color: #2B2A29 !important;
  white-space: nowrap;
  border-radius: 0;
  background: #fff;
  position: relative;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
  padding-bottom: 3px;
  letter-spacing: 0.02em;
  text-transform: inherit;
}

.style-btn__5 > *:active {
  transform: translateY(1px);
}

.style-btn__5 > * svg {
  width: 36px;
  height: 36px;
  fill: #FF5500;
  margin-right: 8px;
  transition: all 0.2s linear;
}

body:not(.mobile) .style-btn__1 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-1:not([disabled]):hover {
  cursor: pointer !important;
  background: #eb4100 !important;
  box-shadow: 0 3px 26px -4px rgba(235, 65, 0, 0.3);
  color: #fff !important;
}

body:not(.mobile) .style-btn__2 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-2:not([disabled]):hover {
  cursor: pointer !important;
  border-color: #eb4100 !important;
  color: #fff !important;
  background: #eb4100;
}

body:not(.mobile) .style-btn__3 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-3:not([disabled]):hover {
  cursor: pointer !important;
  color: #eb4100 !important;
  border-color: #eb4100 !important;
  box-shadow: 0 3px 26px -4px rgba(235, 65, 0, 0.3);
}

body:not(.mobile) .style-btn__3 > *:not([disabled]):hover svg,
body:not(.mobile) .btn__theme-3:not([disabled]):hover svg {
  fill: #eb4100 !important;
}

body:not(.mobile) .style-btn__4 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-4:not([disabled]):hover {
  cursor: pointer !important;
  background: none;
  box-shadow: inset 0 0 0 5px #ffffff;
  color: #fff !important;
}

body:not(.mobile) .style-btn__5 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-5:not([disabled]):hover {
  cursor: pointer !important;
  border-color: #eb4100 !important;
  color: #fff !important;
  background: #eb4100;
}

body:not(.mobile) .style-btn__5 > *:not([disabled]):hover svg,
body:not(.mobile) .btn__theme-5:not([disabled]):hover svg {
  fill: #fff;
}

.btns_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch;
}

.btns_group > * {
  margin-right: 16px;
  margin-bottom: 16px;
}

.btn_l {
  display: flex;
  justify-content: flex-start !important;
}

.btn_c {
  display: flex;
  justify-content: center !important;
}

.btn_r {
  display: flex;
  justify-content: flex-end !important;
}

#go-top {
  position: fixed;
  bottom: 65px;
  right: 10px;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 99;
}

#go-top > * {
  display: flex;
  text-align: center;
  cursor: pointer;
  width: inherit;
  height: inherit;
  background: #FF5500;
  border: 1px solid #FF5500;
  collapse: #eb4100;
  border-radius: 0;
  transform: scale(0.95);
  text-decoration: none;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#go-top > *::after {
  content: '';
  width: 13px;
  height: 13px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(-45deg);
  position: relative;
  top: 3px;
}

#go-top > *:active {
  transform: scale(0.92);
}

.btn-more {
  display: inline-flex;
  min-height: 45px;
  color: #FF5500;
  font: 400 20px/110% 'Montserrat', Helvetica, Arial, sans-serif;
  user-select: none;
  padding: 10px 0;
  text-decoration: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  transition: all 0.2s linear;
  background: #eb4100;
  position: relative;
}

.btn-more:focus,
.btn-more:active {
  color: #FF5500 !important;
  background: #eb4100;
}

.btn-more:focus::after,
.btn-more:active::after {
  color: #FF5500 !important;
}

.btn-more:hover {
  color: white;
  cursor: pointer;
  background: #eb4100;
  padding-left: 3px;
}

.btn-more:hover::after {
  color: white;
  margin-left: 13px;
}

.btn-more:active {
  transform: translateY(2px);
}

.btn-more::after {
  content: 'V';
  padding-top: 2px;
  position: relative;
  top: 2px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 10px;
}

.form-border-style {
  border-width: 1px;
  border-color: #E1E2E7;
  border-style: solid;
}

.border-focus-style {
  border-width: 1px;
  border-color: #ff5500;
  border-style: solid;
}

input:not[type=checkbox] {
  -webkitw: none;
  -webkit-box-appearance: none;
  box-shado-shadow: none;
  border-radius: 0 !important;
  -webkit-appearance: none;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

*:focus {
  outline: none;
}

textarea {
  resize: none;
  color: #2B2A29;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

button::-moz-focus-inner {
  border: 0;
}

a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button:active,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}

a.active.focus button:active,
a.active:focus button:active,
a.focus button:active,
a:active.focus button:active,
a:active:focus button:active,
a:focus button:active,
button:active button:active,
button.active.focus button:active,
button.active:focus button:active,
button.focus button:active,
button:active.focus button:active,
button:active:focus button:active,
button:focus button:active,
.btn.active.focus button:active,
.btn.active:focus button:active,
.btn.focus button:active,
.btn:active.focus button:active,
.btn:active:focus button:active,
.btn:focus button:active,
a.active.focus button:focus,
a.active:focus button:focus,
a.focus button:focus,
a:active.focus button:focus,
a:active:focus button:focus,
a:focus button:focus,
button:active button:focus,
button.active.focus button:focus,
button.active:focus button:focus,
button.focus button:focus,
button:active.focus button:focus,
button:active:focus button:focus,
button:focus button:focus,
.btn.active.focus button:focus,
.btn.active:focus button:focus,
.btn.focus button:focus,
.btn:active.focus button:focus,
.btn:active:focus button:focus,
.btn:focus button:focus {
  outline: none !important;
}

*:focus,
*:active,
*::-moz-focus-inner {
  outline: none !important;
  border: 0;
  outline: 0 !important;
  outline-offset: 0 !important;
}

form.form_style_1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

form.form_style_1 > * {
  position: relative;
}

form.form_style_1 > * > * {
  position: relative;
}

.form_style_1 button:not(.btn_search):not(.icn_btn):not(.btn_search2):not(.slick-arrow),
.form_style_1 input[type="button"]:not(.btn_search):not(.btn_search2),
.form_style_1 input[type="submit"]:not(.btn_search):not(.btn_search2),
.form_style_1 input[type="reset"]:not(.btn_search):not(.btn_search2) {
  border: none;
  background: #FF5500;
  outline: none;
  border-radius: 0 !important;
  width: auto;
  min-width: 120px;
  height: 54px;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  font: 400 16px/113% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: 2px 24px 0;
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0 20px, 20px 0);
  transition: all 0.2s ease-in-out;
}

.form_main_parameters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #626268;
  border-radius: 0;
  border-width: 1px;
  border-color: #E1E2E7;
  border-style: solid;
  background: #ffffff;
  backdrop-filter: blur(0px);
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
  padding: 0 20px 0 19.2px;
}

.form_style_1 input:not(#braceletText):not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]):not([type="range"]):not(.input_avatar) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #626268;
  border-radius: 0;
  border-width: 1px;
  border-color: #E1E2E7;
  border-style: solid;
  background: #ffffff;
  backdrop-filter: blur(0px);
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
  padding: 0 20px 0 19.2px;
}

.form_style_1 input:not(#braceletText):not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]):not([type="range"]):not(.input_avatar):focus {
  border-width: 1px;
  border-color: #ff5500;
  border-style: solid;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  color: #2B2A29;
}

.form_style_1 textarea,
.form_style_1 select,
.form_style_1 .file_upload > div,
.form_style_1 .type_color_box,
.form_style_1 .inputfile_6 + label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #626268;
  border-radius: 0;
  border-width: 1px;
  border-color: #E1E2E7;
  border-style: solid;
  background: #ffffff;
  backdrop-filter: blur(0px);
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
  padding: 0 20px 0 19.2px;
}

.form_style_1 textarea:focus,
.form_style_1 select:focus,
.form_style_1 .file_upload > div:focus,
.form_style_1 .type_color_box:focus,
.form_style_1 .inputfile_6 + label:focus {
  border-width: 1px;
  border-color: #ff5500;
  border-style: solid;
}

.form_style_1 .custom_select_container {
  background: #ffffff;
  border-width: 1px;
  border-color: #E1E2E7;
  border-style: solid;
  height: 54px;
  display: block;
  width: 100%;
  top: 0;
  border-radius: 0;
  z-index: 1;
}

.form_style_1 .custom_select_container select {
  border: none !important;
  background: none;
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0 40px 2px 19.2px;
  color: #626268;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
}

.form_style_1 .custom_select_container select::-ms-expand {
  display: none !important;
}

.form_style_1 .custom_select_container select:focus {
  color: #2B2A29 !important;
}

.form_style_1 .custom_select_container select:not(:focus) {
  color: #626268 !important;
}

.form_style_1 .custom_select_container::before {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  content: '\54';
  width: 54px;
  height: 54px;
  position: absolute;
  z-index: -1;
  font-size: 20px;
  right: 3px;
  top: 50%;
  transform: translateY(-50%) scale(1);
  color: #9C9DA3;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form_style_1 .custom_select_container.active::before {
  color: #eb4100;
  transform: translateY(-50%) scale(-1);
}

.custom_select_container option {
  height: 54px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
}

body:not(.mobile) .form_style_1 .custom-select_container:hover {
  cursor: pointer;
}

body:not(.mobile) .form_style_1 .custom-select_container:hover::before {
  color: #eb4100;
}

.select_box {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 54px;
  max-height: 54px;
  cursor: pointer;
}

.select_box.active > div {
  align-self: start;
  top: 0;
  background: #fff;
  box-shadow: inset 0 0 1px 0 #F2F3F5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);
}

.select_box.active > div > *:not(:last-child) {
  box-shadow: inset 0 -1px 0 0 #F2F3F5;
}

.select_box.active > div > *:first-child:after {
  transform: translateY(-50%) scaleY(-1);
}

.select_box:not(.active) > div > *:not(:first-child) {
  display: none;
  cursor: pointer;
}

.select_box * {
  transition: all 0.2s ease-out;
}

.select_box > div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  position: relative;
  height: auto;
  z-index: 10;
}

.select_box > div > * {
  display: block;
  text-align: left;
  padding: 0 20px 0 8px;
  text-decoration: underline;
  height: 30px;
  font: 400 21px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  color: #2B2A29;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  white-space: nowrap;
}

.select_box > div > *:hover {
  cursor: pointer !important;
}

.select_box > div > *:first-child:after {
  content: "\54";
  display: block;
  position: absolute;
  font-size: 11px;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #E1E2E7;
  z-index: 0;
}

.select_box.select_box_theme1 {
  border-radius: 0;
  background: #2B2A29;
}

.select_box.select_box_theme1:not(.active) > div > *:not(:first-child) {
  display: none;
}

.select_box.select_box_theme1.active > div {
  align-self: start;
  top: 0;
  background: #2B2A29;
  -webkit-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
}

.select_box.select_box_theme1.active > div > * {
  color: #fff;
}

.select_box.select_box_theme1.active > div > *:not(:last-child) {
  box-shadow: inset 0 -1px 0 0 #626268;
}

.select_box.select_box_theme1.active > div > *:not(:first-child):hover {
  background: none;
  color: #FF5500;
}

.select_box.select_box_theme1.active > div > *:first-child:after {
  transform: translateY(-50%) scaleY(-1);
}

.select_box.select_box_theme1 * {
  cursor: default;
  user-select: none !important;
  transition: all 0.2s ease-out;
}

.select_box.select_box_theme1 > div {
  z-index: 10;
  border-radius: 0;
}

.select_box.select_box_theme1 > div > * {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: left;
  padding: 0 50px 0 20px;
  min-height: 54px;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #F2F3F5;
}

.select_box.select_box_theme1 > div > *:first-child:after {
  content: '\54';
  display: block;
  position: absolute;
  font-size: 20px;
  top: 27px;
  right: 25px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #E1E2E7;
}

body:not(.mobile) .select_box.select_box_theme1:hover {
  cursor: pointer !important;
}

body:not(.mobile) .select_box.select_box_theme1.active > div > *:not(:first-child):hover {
  background: none;
  color: #FF5500;
  cursor: pointer !important;
}

.select_box.select_box_theme2 {
  border-radius: 0;
  background: none;
  border: none;
}

.select_box.select_box_theme2:not(.active) > div > *:not(:first-child) {
  display: none;
}

.select_box.select_box_theme2.active > div {
  align-self: start;
  top: 0;
  background: #ffffff;
  box-shadow: none;
}

.select_box.select_box_theme2.active > div > * {
  color: #2B2A29;
}

.select_box.select_box_theme2.active > div > *:first-child:after {
  color: #eb4100;
}

.select_box.select_box_theme2.active > div > *:not(:last-child) {
  box-shadow: inset 0 -1px 0 0 #F2F3F5;
}

.select_box.select_box_theme2.active > div > *:not(:first-child):hover {
  background: none;
  color: #FF5500;
}

.select_box.select_box_theme2.active > div > *:first-child:after {
  transform: translateY(-50%) scaleY(-1);
}

.select_box.select_box_theme2 * {
  cursor: default;
  user-select: none !important;
  transition: all 0.2s ease-out;
}

.select_box.select_box_theme2 > div {
  z-index: 10;
  border-radius: 0;
  background: #ffffff;
  border-width: 1px;
  border-color: #E1E2E7;
  border-style: solid;
}

.select_box.select_box_theme2 > div > * {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: left;
  padding: 0 50px 0 20px;
  min-height: 54px;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #626268;
}

.select_box.select_box_theme2 > div > *:first-child:after {
  content: '\54';
  display: block;
  position: absolute;
  font-size: 20px;
  top: 27px;
  right: 25px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #9C9DA3;
}

body:not(.mobile) .select_box.select_box_theme1:hover {
  cursor: pointer !important;
}

body:not(.mobile) .select_box.select_box_theme1.active > div > *:not(:first-child):hover {
  background: none;
  color: #FF5500;
  cursor: pointer !important;
}

.form_style_1 .type-week,
.form_style_1 .type-date {
  background: none;
}

.form_style_1 .type-week:before,
.form_style_1 .type-date:before {
  content: '\4d';
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  color: #E1E2E7;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form_style_1 .type-week:after,
.form_style_1 .type-date:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 40px;
  background: #fff;
}

.form_style_1 .type-week input,
.form_style_1 .type-date input {
  z-index: 3;
  background: none !important;
}

.placeholder_style_inputs {
  color: #626268 !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
}

.placeholder_style_textarea {
  color: #626268;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
}

.placeholder_focus {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
}

input::-webkit-input-placeholder {
  color: #626268 !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
}

textarea::-webkit-input-placeholder {
  color: #626268;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
}

input::-moz-placeholder {
  color: #626268 !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
  line-height: 52px;
}

textarea::-moz-placeholder {
  color: #626268;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
  line-height: 52px;
}

input:-moz-placeholder {
  color: #626268 !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
}

textarea:-moz-placeholder {
  color: #626268;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
}

input:-ms-input-placeholder {
  color: #626268 !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
}

textarea:-ms-input-placeholder {
  color: #626268;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
}

textarea:focus {
  color: #2B2A29 !important;
}

.checkbox,
.radio {
  position: relative;
  cursor: pointer;
}

.checkbox *,
.radio * {
  cursor: default;
  user-select: none !important;
  cursor: pointer !important;
  width: 20px;
  height: 20px;
}

.checkbox::selection,
.radio::selection {
  background: transparent;
}

.checkbox input ~ div.mask,
.radio input ~ div.mask {
  background: #fff;
  border: 1px solid #c3c4c9 !important;
  content: "";
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.checkbox input:not(:checked) ~ div.mask::after,
.radio input:not(:checked) ~ div.mask::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s;
}

.checkbox input:not[disabled]:active ~ div.mask,
.radio:not[disabled] input:active ~ div.mask {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:focus ~ div.mask,
.radio input:focus ~ div.mask {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:checked ~ div.mask::after,
.radio input:checked ~ div.mask::after {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox input,
.radio input {
  position: absolute;
  opacity: 0;
}

.checkbox input ~ div.mask {
  border-radius: 3px;
  pointer-events: none;
}

.checkbox input ~ div.mask::after {
  content: '\53';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #FF5500;
  font-size: 16px;
  width: 14px;
  height: 14px;
}

.radio input ~ div.mask {
  border-radius: 100%;
}

.radio input ~ div.mask::after {
  content: '';
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  transform: translate(-50%, -50%) scale(0);
}

.radio input:checked ~ div.mask::after {
  background: #eb4100;
}

.form_style_1 input[type="checkbox"] ~ .form_err_def {
  display: block;
  background: #2B2A29;
  border: 1px solid #626268;
  color: #F2F3F5;
  padding: 10px 13px;
  border-radius: 0 !important;
  position: absolute;
  height: auto !important;
  bottom: -35px !important;
  top: auto !important;
  left: -4px !important;
}

.form_style_1 input[type="checkbox"] ~ .form_err_def:before {
  content: "";
  display: block;
  transform: rotate(-45deg);
  width: 10px;
  height: 10px;
  position: absolute !important;
  top: -6px;
  left: 7px;
  background: #fff;
  border-top: 1px solid #F04400;
  border-right: 1px solid #F04400;
  z-index: -1;
}

.form_style_1 label {
  z-index: 1;
}

.form_style_1 .textarea_box {
  width: 100%;
  height: auto;
  position: relative;
}

.form_style_1 textarea {
  padding-top: 16px;
  padding-bottom: 14px;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 100px;
  resize: none;
  border-radius: 0;
}

.form_style_1 .inputfile-box {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #626268;
  border-radius: 0;
  border: none !important;
  margin-bottom: 16px !important;
}

.form_style_1 .inputfile-box .inputfile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #626268;
  border-radius: 0;
  border-width: 1px;
  border-color: #E1E2E7;
  border-style: solid;
  opacity: 0 !important;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form_style_1 .inputfile-box .inputfile ~ div.placeh {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  pointer-events: none;
  font: 400 15px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  color: #2B2A29;
}

.form_style_1 .inputfile-box .inputfile ~ label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #626268;
  border-radius: 0;
  position: relative;
  background: #ffffff;
  border: 1px solid #E1E2E7 !important;
  padding: 0 64px 0 20px;
  cursor: pointer !important;
  outline: none !important;
}

.form_style_1 .inputfile-box .inputfile ~ label > span {
  overflow: hidden;
  z-index: 2;
}

.form_style_1 .inputfile-box .inputfile ~ label > strong {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  z-index: 3;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.form_style_1 .inputfile-box .inputfile ~ label > strong::before {
  content: "\58";
  font-size: 18px;
  color: #9C9DA3;
  z-index: 5;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form_style_1 .inputfile-box .inputfile ~ label:hover > strong::before {
  color: #eb4100;
}

.form_style_1 .inputfile-box.attach .inputfile ~ div.placeh {
  opacity: 0 !important;
}

.form_style_1 .no-js .inputfile + label {
  display: none;
}

.form_style_1 .input_avatar {
  width: 108px !important;
  height: 108px !important;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  box-shadow: none;
  cursor: pointer;
}

.form_style_1 .input_avatar + label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  width: 108px;
  height: 108px;
  outline: none !important;
  border-radius: 100%;
  background: url("/images/avatar_bg.png") 50% 50% no-repeat;
  background-size: cover;
}

.form_style_1 .input_avatar + label > span {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100% !important;
  border-radius: 100%;
  z-index: 2;
  overflow: hidden;
  background: url("/images/avatar_bg.png");
}

.form_style_1 .input_avatar + label > span img {
  -o-object-fit: cover !important;
  object-fit: cover !important;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover;';
}

.form_style_1 .input_avatar + label > strong {
  position: absolute;
  bottom: -6px;
  left: 80px;
  z-index: 3;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #FF5500;
  border-radius: 0;
  box-shadow: none;
}

.form_style_1 .input_avatar + label > strong::before {
  content: "\58";
  color: #fff;
  z-index: 5;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

.form_style_1 .input_avatar + label:hover > strong::before {
  color: #fff;
}

.form_style_1 .no-js .input_avatar + label {
  display: none;
}

.form_style_1 .input_avatar:focus + label,
.form_style_1 .input_avatar.has_focus + label {
  outline: none;
}

.form_style_1 .input_avatar:focus + label,
.form_style_1 .input_avatar.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.user_profile_avatar,
.user_profile_avatar *,
.user_profile_avatar input:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}

.form_style_1 .search_box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: 56px;
}

.form_style_1 .search_box > input[type="search"],
.form_style_1 .search_box > input[type="text"] {
  flex: 1 1 100%;
  position: relative;
  padding-right: 54px !important;
}

.form_style_1 .search_box > button,
.form_style_1 .search_box > input[type='button'],
.form_style_1 .search_box > input[type='submit'] {
  width: 54px !important;
  height: 56px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 2;
  background: none !important;
  padding: 0 !important;
  border: none !important;
}

.form_style_1 .search_box > button::before,
.form_style_1 .search_box > input[type='button']::before,
.form_style_1 .search_box > input[type='submit']::before {
  content: '\e041';
  font-size: 18px;
  color: #FF5500;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form_style_1 .search_box > button:hover::before,
.form_style_1 .search_box > input[type='button']:hover::before,
.form_style_1 .search_box > input[type='submit']:hover::before {
  color: #eb4100;
}

.form_style_1 input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-right: 0;
  cursor: pointer;
  background: url('/images/icn_close.svg') 50% 50% no-repeat;
  background-size: 10px auto !important;
  opacity: 0.6;
}

.form_style_1 input[type="search"]::-webkit-search-cancel-button:hover {
  opacity: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.quantity {
  position: relative;
  height: 54px;
}

.quantity input {
  width: 45px;
  height: 42px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 1px solid #eee;
}

.quantity input:focus {
  outline: 0;
}

.quantity .quantity-nav {
  position: absolute;
  right: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.quantity .quantity-nav .quantity-button {
  position: relative;
  cursor: pointer;
  background: #9C9DA3;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #fff;
  margin-left: 5px;
  border-radius: 0;
  transition: all 0.2s ease-out;
  transform: scale(0.8);
  cursor: default;
  user-select: none !important;
}

.quantity .quantity-nav .quantity-button:active {
  transform: scale(0.7);
}

.quantity .quantity-nav .quantity-button.quantity-up::before {
  content: '\e035';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.quantity .quantity-nav .quantity-button.quantity-down::before {
  content: '\e024';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.quantity input[type=hidden] ~ .quantity-nav {
  display: none !important;
}

body:not(.mobile) .quantity-nav .quantity-button:hover {
  cursor: pointer;
  background-color: #FF5500;
  color: #fff !important;
}

.form_style_1 .type_color_box {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.form_style_1 .type_color_box:before {
  content: 'Choice color:';
  height: 100%;
  text-align: left;
  width: auto;
  position: absolute;
  opacity: 0.9;
  border-right: 1px solid #626268;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #fff;
  display: block;
  z-index: 1;
}

.form_style_1 input[type=color] {
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: block;
  position: absolute;
  border: none;
  padding: 0;
  overflow: hidden;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  cursor: pointer;
}

.form_style_1 .form_err input:not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]),
.form_style_1 .form_err textarea,
.form_style_1 .form_err select,
.form_style_1 .form_err .file_upload > div,
.form_style_1 .form_err .type_color_box,
.form_style_1 .form_err .inputfile_6 + label {
  border-color: #E95725;
  background: rgba(231, 76, 60, 0.05) !important;
}

.custom_select_container > div.err_info {
  position: relative;
  left: 0;
  z-index: 8;
  display: block;
  float: none;
  clear: both;
  width: 100% !important;
}

.vgap > * {
  margin-bottom: 48px;
  position: relative;
  width: 100%;
}

.form_footer1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;
}

.form_footer1 > * {
  flex-basis: auto;
  margin-bottom: 10px;
}

.form_footer1 > *:first-child {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form_footer1 > *:first-child > * {
  flex-basis: auto;
  margin-right: 18px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.form_footer2 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 20px;
}

.form_footer2 > * {
  flex-basis: 50%;
  padding-left: 16px;
  padding-right: 16px;
}

.form_footer2 > * > * {
  width: 100%;
}

.eye {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 54px;
  height: 54px;
  position: absolute !important;
  z-index: 4;
  right: 0;
  top: 0;
  transition: all 0.2s linear;
}

.eye:hover {
  cursor: pointer;
}

.eye:active {
  transform: translateY(1px) scale(0.95);
}

.eye.visible::after {
  color: #FF5500;
}

.form__footer {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.form__footer > * {
  display: flex;
  font-size: 15px !important;
}

.form__footer > * > *:not(:first-child) {
  margin-left: 16px;
}

.drop_links_box {
  margin: 0 5px !important;
  cursor: default;
  user-select: none !important;
  font: 400 17px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  z-index: 10;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  align-self: flex-start !important;
}

.drop_links_box,
.drop_links_box * {
  transition: 0.3s;
}

.drop_links_box > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  padding-right: 15px;
  height: 26px;
}

.drop_links_box > div:nth-child(1) > span {
  border: 1px dashed #626268;
}

.drop_links_box > div:nth-child(1)::after {
  content: '\e04d';
  position: absolute;
  top: 40%;
  right: 2px;
  transform: translateY(-50%) scaleY(1);
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #E1E2E7;
}

.drop_links_box > div:nth-child(1):hover {
  cursor: pointer !important;
}

.drop_links_box > div:nth-child(2) {
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0) translateY(0);
  position: absolute;
  top: 26px;
  transform-origin: 50% 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;
  background: #fff;
  box-shadow: none;
  border-radius: 0;
  padding: 10px;
}

.drop_links_box > div:nth-child(2)::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 0;
  background: #fff;
  transform: rotate(-45deg) translateX(-50%);
  top: -5px;
  left: 50%;
  width: 10px;
  height: 10px;
}

.drop_links_box > div:nth-child(2) > * {
  padding: 5px;
  text-decoration: none;
  color: #2B2A29;
}

.drop_links_box > div:nth-child(2) > *:not(:last-child) {
  border-bottom: 1px solid #F2F3F5;
}

.drop_links_box > div:nth-child(2) > *:hover {
  cursor: pointer;
}

.drop_links_box.active > div:nth-child(1)::after {
  top: 52%;
  transform: translateY(-50%) scaleY(-1);
  color: #FF5500;
}

.drop_links_box.active > div:nth-child(2) {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1) translateY(0);
}

.field-wrap {
  width: 100%;
  position: relative;
}

.label_bottom {
  position: absolute !important;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 4;
  top: 0;
  left: 0;
  height: 54px;
  color: #626268;
  padding-left: 19.2px !important;
  opacity: 1;
  font: 400 17px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  transition: 0.1s;
  pointer-events: none;
}

input:focus:placeholder-shown ~ .label_bottom,
textarea:focus:placeholder-shown ~ .label_bottom,
select:focus:placeholder-shown ~ .label_bottom,
input:not(:placeholder-shown) ~ .label_bottom,
textarea:not(:placeholder-shown) ~ .label_bottom,
select:not(:placeholder-shown) ~ .label_bottom {
  height: 48px;
  top: -37.63636364px;
  left: -18px;
  font: 400 13px/140% 'Montserrat', Helvetica, Arial, sans-serif;
  color: #2B2A29;
}

span.error {
  color: #E95725;
  font: 400 13px/110% 'Montserrat', Helvetica, Arial, sans-serif;
  position: absolute;
  left: 0;
  text-align: left;
  bottom: -24px;
  width: 100%;
  padding: 0 13px 5px 0;
  border-top: none;
  z-index: 7;
  background: none;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #F2F3F5;
}

input[type=range]:focus::-ms-fill-lower {
  background: #FF5500;
}

input[type=range]:focus::-ms-fill-upper {
  background: #eb4100;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #F2F3F5;
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

input[type=range]::-webkit-slider-thumb {
  transition: all 0.2s ease-out;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: #FF5500;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type=range]::-webkit-slider-thumb:hover,
input[type=range]::-webkit-slider-thumb:active {
  background: #eb4100;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #F2F3F5;
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

input[type=range]::-moz-range-thumb {
  transition: all 0.2s ease-out;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: #FF5500;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb:hover,
input[type=range]::-moz-range-thumb:active {
  background: #eb4100;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower,
input[type=range]::-ms-fill-upper {
  background: #F2F3F5;
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

input[type=range]::-ms-thumb {
  transition: all 0.2s ease-out;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: #FF5500;
  cursor: pointer;
}

input[type=range]::-ms-thumb:hover,
input[type=range]::-ms-thumb:active {
  background: #eb4100;
}

[disabled] {
  opacity: 0.5;
}

.hide {
  display: none !important;
}

.form-wrap {
  position: relative;
}

.form-wrap._sending > .form-loader {
  opacity: 1;
  visibility: visible;
}

.form-wrap > .form-loader {
  position: absolute;
  width: 104%;
  height: 104%;
  content: '';
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s linear;
  transform: translate(-2%, -2%);
}

.form-wrap > .form-loader::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg width="60px" height="60px" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M56.9875 114C6.45014 114 -19.0715 52.485 16.6942 16.7193C52.6876 -19.2741 114 6.87989 114 57.0126C113.747 62.5772 104.034 62.1725 104.034 57.0126C104.034 15.6316 51.2458 -7.81592 21.5507 21.8792C-10.8004 54.5338 12.4701 112.508 56.684 112.508C59.4158 112.735 59.0616 113.772 56.9875 114Z" fill="rgb(48, 174, 224)"/></svg>');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  transform-origin: 50% !important;
  display: flex;
  animation: spin 0.5s infinite linear;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

body::after {
  content: '';
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(23, 22, 21, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
}

body.popup__opened::after {
  opacity: 1;
  visibility: visible;
}

body.popup__opened .main-container {
  filter: blur(1px);
}

.header {
  transition: none;
}

.popup {
  position: fixed;
  transition: 0.2s;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
}

.popup__container {
  width: 100%;
  min-height: 100%;
  padding: 30px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__container .popup__window {
  position: relative;
  width: 100%;
  max-width: 680px;
  padding: 32px;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 13px 60px -5px rgba(13, 12, 11, 0.4);
  color: #2B2A29;
  transition: all 0.2s ease-out;
  transform: perspective(600) translateY(40%) rotateX(10deg);
}

.popup__container .popup__window .popup__title {
  margin-bottom: 15px;
}

.popup__container .popup__window .popup__sub-title {
  margin-bottom: 15px;
}

.popup__container .popup__close {
  text-decoration: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: 0.2s;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 15;
  background: #FF5500;
  border-radius: 0;
  box-shadow: 0 3px 20px -1px rgba(225, 55, 0, 0.4);
}

.popup__container .popup__close:hover {
  cursor: pointer;
  background: #244474;
}

.popup__container .popup__close:active {
  transform: translateY(1px);
}

.popup__container .popup__close::before,
.popup__container .popup__close::after {
  content: '';
  display: flex;
  top: 50%;
  left: 50%;
  background: #fff;
  width: 3px;
  height: 50%;
  position: absolute;
  transform-origin: 50% 50%;
}

.popup__container .popup__close::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.popup__container .popup__close::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.popup.popup__open {
  opacity: 1;
  visibility: visible;
}

.popup.popup__open .popup__window {
  transform: perspective(600) translateY(0) rotateX(0);
}

.popup__big-icon img,
.popup__big-icon svg {
  pointer-events: none;
  width: 270px;
  fill: #FF5500;
}

.popup__title {
  font: 600 30px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  margin-bottom: 15px;
}

.popup__text {
  margin-bottom: 32px;
}

.popup__big-icon svg {
  width: 220px;
  height: 220px;
  fill: #FF5500;
}

.popup__footer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.popup .checkbox {
  padding: 10px 0;
}

.cookie-banner {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: calc(100% - 16px * 2);
  max-width: 420px;
  transform: translate(120%, 0);
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(3px);
  color: #2B2A29;
  padding: 24px;
  text-align: center;
  border-radius: 0;
  z-index: 1000;
  box-shadow: 0 4px 60px -3px rgba(43, 42, 41, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.05);
  transition: all 0.23s linear;
}

.cookie-banner.show {
  transform: translate(0, 0);
  opacity: 1;
}

.cookie-banner header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 16px;
}

.cookie-banner header svg {
  width: 18px;
  fill: #FF5500;
  margin-right: 8px;
}

.cookie-banner header h4 {
  font-size: 22px;
}

.cookie-banner header .cookie-banner p {
  font-size: 15px;
  margin: 0;
  width: 100%;
  display: inline-block;
}

.cookie-banner header .cookie-banner a {
  color: #FF5500;
  text-decoration: underline;
}

.cookie-banner__buttons {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  gap: 16px;
}

.close-cookie-popup-btn {
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  outline: none;
  border: 0 !important;
}

.close-cookie-popup-btn svg {
  width: 16px;
  height: 16px;
  fill: #FF5500 !important;
}

.cookie-banner__buttons {
  margin-top: 16px;
}

.cookie-banner__buttons button {
  min-width: 110px;
  font-size: 15px;
}

.footer {
  width: 100%;
  position: relative;
  z-index: 3;
  background: #103060;
  color: #fff;
}

.footer__copyright {
  padding: 16px;
  position: relative;
  font-size: 14px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.section__error {
  display: flex;
  min-height: calc(var(--app-height));
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding: 32px 16px !important;
  background: url("/images/bg-section-blue.jpg");
  background-size: cover !important;
}

.section__error > * {
  position: relative;
  z-index: 3;
}

.section__error .section__error__number {
  font-size: 120px;
  color: #FF5500;
  font-weight: 900 !important;
  line-height: 150%;
  display: flex;
  text-align: center;
}

.section__error .error__details {
  text-align: center;
  color: #fff;
  margin-bottom: 32px;
}

.section__error .error__details p {
  font: 600 22px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  letter-spacing: 0.05em;
}

.color-w {
  color: #fff;
}

.pre-title {
  font: 600 18px/120% 'Montserrat', Helvetica, Arial, sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #9C9DA3;
}

.title {
  text-align: center;
  margin-bottom: 16px;
}

.title > * {
  font: 900 56px/120% 'BarlowC', Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.03em;
}

.title b {
  color: #FF5500 !important;
}

.title-2 > * {
  font: 900 28px/120% 'Montserrat', Helvetica, Arial, sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.title-2 b {
  color: #FF5500 !important;
}

.mw-text {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}

.mw-columns {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.gap-x2 {
  gap: 32px;
}

.mb-x3 {
  margin-bottom: 64px;
}

.title-subtitle .title {
  margin-bottom: 8px !important;
}

.title-subtitle .title h2 {
  font: 900 56px/115% 'BarlowC', Helvetica, Arial, sans-serif !important;
}

.title-subtitle .sub-title h5 {
  font: 600 18px/115% 'Montserrat', Helvetica, Arial, sans-serif !important;
}

.sec-welcome {
  position: relative;
  background: #103060;
  height: calc(var(--app-height) - 160px);
  min-height: 860px;
  max-height: 1080px;
  width: 100%;
  display: flex;
  z-index: 3;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.sec-welcome .resolution-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.sec-welcome__content {
  display: flex;
  position: relative;
  z-index: 2;
  width: 100%;
}

.sec-welcome__content__box {
  width: 100%;
  max-width: 620px;
  color: #fff;
}

.sec-welcome__content__box h1 {
  font: 400 56px/110% 'BarlowC';
  margin-bottom: 8px;
}

.sec-welcome__content__box h1 b {
  display: block;
}

.sec-welcome__content__box p {
  font-size: 26px;
  line-height: 110%;
  margin-bottom: 16px;
}

.sec-welcome .bg-city {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-size: cover !important;
}

.sec-welcome .bg-city .light {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.sec-welcome .bg-city .light::before {
  content: '';
  display: block;
  width: 80%;
  height: 80%;
  background: radial-gradient(circle, #426292 0%, #103060 100%);
  position: absolute;
  border-radius: 100%;
  filter: blur(200px);
  left: 50%;
  top: 50%;
  transform: translate(-20%, -40%);
}

.sec-welcome .bg-city .inclined {
  display: block;
  clip-path: polygon(0 90%, 100% 50%, 100% 100%, 0% 100%);
  background: #fff;
  width: 100%;
  height: 660px;
  position: absolute;
  bottom: -1px;
  z-index: 3;
}

.sec-welcome .bg-city .city {
  width: 75%;
  min-width: 520px;
  max-width: 840px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(90%, -10%);
  z-index: 50;
}

.sec-welcome .bg-city .city [class*='wifi__'] {
  width: 5%;
  padding-bottom: 6%;
  background: url("/images/wifi-logo-svgrepo-com.png") 50% 50% no-repeat;
  background-size: 100% auto !important;
  position: absolute;
  z-index: 4;
  animation: city 10s ease-in-out infinite;
}

.sec-welcome .bg-city .city [class*='wifi__']::before {
  content: '';
  display: block;
  width: 100px;
  height: 100px;
  background: #fff;
  position: absolute;
  left: 48%;
  transform: translate(-50%, -50%) scale(0);
  top: 50%;
  border-radius: 100%;
  opacity: 0;
}

.sec-welcome .bg-city .city [class*='wifi__']::after {
  content: '';
  display: block;
  width: 1px;
  height: 80%;
  border-right: 1px dashed #fff;
  position: absolute;
  left: 48%;
  transform: translateX(-50%);
  top: 50px;
}

.sec-welcome .bg-city .city [class*='__1'] {
  top: 10%;
  left: 18%;
}

.sec-welcome .bg-city .city [class*='__1']::before {
  animation: signal 10s linear 3s infinite;
}

.sec-welcome .bg-city .city [class*='__2'] {
  top: -2%;
  left: 30%;
}

.sec-welcome .bg-city .city [class*='__2']::before {
  animation: signal 10s linear 1s infinite;
}

.sec-welcome .bg-city .city [class*='__3'] {
  top: -10%;
  left: 48%;
}

.sec-welcome .bg-city .city [class*='__3']::before {
  animation: signal 10s linear 5s infinite;
}

.sec-welcome .bg-city .city [class*='__4'] {
  top: 7%;
  left: 77%;
}

.sec-welcome .bg-city .city [class*='__4']::before {
  animation: signal 10s linear 1s infinite;
}

.sec-welcome .bg-city .city [class*='__5'] {
  top: 45%;
  left: 52%;
}

.sec-welcome .bg-city .city [class*='__5']::before {
  animation: signal 10s linear 8s infinite;
}

.sec-welcome .bg-city .city [class*='__6'] {
  top: 25%;
  left: 62%;
}

.sec-welcome .bg-city .city [class*='__6']::before {
  animation: signal 10s linear 3.5s infinite;
}

.sec-welcome .bg-city .city [class*='__7'] {
  top: 35%;
  left: 35%;
}

.sec-welcome .bg-city .city [class*='__7']::before {
  animation: signal 10s linear 4s infinite;
}

.sec-welcome .bg-city .city [class*='__8'] {
  top: 22%;
  left: 49%;
}

.sec-welcome .bg-city .city [class*='__8']::before {
  animation: signal 10s linear 6.5s infinite;
}

.sec-welcome .bg-city .city > .city-img {
  width: 100%;
  background: url("/images/city-main.png") 50% 50% no-repeat;
  background-size: 100% auto !important;
  padding-bottom: 70%;
  animation: city 10s linear infinite;
}

@keyframes city {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

@keyframes signal {
  0%, 100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }

  5% {
    opacity: 0.1;
  }

  10% {
    opacity: 0.2;
  }

  14% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0;
  }
}

.sec-about {
  padding-top: 64px;
  padding-bottom: 64px;
}

.sec-about__content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.sec-about__content__illustration svg {
  width: 500px;
}

.soc-services {
  background: url("/images/bg-section-blue.jpg") 0 50%;
  background-size: cover !important;
  padding-top: 48px;
  padding-bottom: 48px;
}

.soc-services__content {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 124px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 32px;
}

.soc-services__content__title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.soc-services__content__title h3 {
  text-align: center;
  font: 600 32px/125% 'Montserrat', Helvetica, Arial, sans-serif;
}

.soc-services__content__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex: 0 1 384px;
  padding-left: 24px;
  padding-right: 24px;
}

.soc-services__content__item h3 {
  font: 600 24px/110% 'Montserrat', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  order: 1;
}

.soc-services__content__item p {
  font-size: 16px;
  order: 2;
}

.soc-services__content__item .icon svg {
  width: 64px;
  height: 64px;
  order: 0;
}

.sec-how-it-works {
  padding: 64px 0;
}

.sec-how-it-works .hiw-list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 32px;
}

.sec-how-it-works .hiw-list__item {
  padding-right: 16px;
  flex: 0 1 240px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  text-align: center;
}

.sec-how-it-works .hiw-list__item__icon {
  margin-bottom: 16px;
}

.sec-how-it-works .hiw-list__item::before {
  counter-increment: num !important;
  color: #fff;
  background: #eb4100;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  line-height: 17px;
  left: 8px;
  top: 0;
  font-size: 20px;
  font-weight: 900;
  border-radius: 100%;
}

.sec-how-it-works .hiw-list__item h3 {
  font: 600 18px/110% 'Montserrat', Helvetica, Arial, sans-serif;
  margin-bottom: 8px;
}

.sec-how-it-works .hiw-list__item p {
  font-size: 16px;
}

.section-type-4 {
  background: url("/images/bg-section-blue.jpg") 0 50%;
  background-size: cover !important;
  padding-top: 48px;
  padding-bottom: 48px;
}

.section-type-4 .footer-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.section-type-4 .footer-block p {
  font-size: 24px;
}

.snake-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 64px;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  counter-reset: num;
}

.snake-list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 32px;
}

.snake-list__item:nth-child(2n+2) {
  margin-left: auto;
}

.snake-list__item__text {
  max-width: 350px;
  font: 400 24px/130% 'Montserrat', Helvetica, Arial, sans-serif;
}

.snake-list__item__icon {
  position: relative;
  border: 4px solid #FF5500;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.snake-list__item__icon::before {
  content: counter(num) ' ' !important;
  counter-increment: num !important;
  color: #fff;
  background: #eb4100;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  line-height: 17px;
  left: 0;
  top: 0;
  font-size: 20px;
  font-weight: 900;
  border-radius: 100%;
}

.snake-list__item__icon svg {
  width: 80px;
  height: 80px;
  min-width: 80px;
}

.sec-hr-block {
  background: url("/images/bg-section-blue.jpg") 0 50%;
  background-size: cover !important;
  padding-top: 48px;
  padding-bottom: 48px;
}

.sec-hr-block__content {
  display: flex;
}

.sec-hr-block__content__text {
  padding-right: 32px;
}

.sec-consultation {
  padding: 48px 0;
  background: #F2F3F5;
}

.sec-consultation .title {
  margin-bottom: 48px;
}

.form-columns {
  display: flex;
  width: calc(100% + 16px * 2) !important;
  left: -16px;
  margin-bottom: 0 !important;
  position: relative;
  flex-wrap: wrap;
}

.form-columns > * {
  flex: 1 1 320px;
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 48px;
}

.form-columns > * > div {
  position: relative;
}

.sec-faq {
  padding: 48px 0;
}

.sec-faq .title {
  margin-bottom: 48px;
}

.send-message {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.send-message p {
  font-size: 22px;
  margin-bottom: 32px;
}

.send-message svg {
  width: 220px;
  height: 220px;
  fill: #FF5500;
}

@keyframes lightanim {
  0%, 100% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.23;
  }
}

@keyframes icons {
  0%, 100% {
    transform: translate(-100%, 0);
  }

  50% {
    transform: translate(-100%, -5px);
  }
}

body:not(.mobile) .main-section__content-box .social-networks a:hover svg {
  stroke: #fff;
}

body:not(.mobile) .main-section__content-box .click-game .button:hover {
  cursor: pointer;
}

body:not(.mobile) .main-section__content-box .click-game .button:active {
  transform: scale(0.96);
}

body:not(.mobile) .main-section .phones__wrap a:hover {
  color: #fff;
}

body:not(.mobile) .main-section .email a:hover {
  color: #fff;
}

.email-copy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 6px;
}

.email-copy a {
  font: 600 20px/120% 'Montserrat', Helvetica, Arial, sans-serif;
}

.email-copy__btn {
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  top: 1px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 8px;
}

.email-copy__btn::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FF5500;
  border-radius: 100%;
  z-index: 0;
}

.email-copy__btn.copy::after {
  animation: copybtn 0.8s linear forwards;
}

.email-copy__btn svg {
  width: 18px;
  height: 18px;
  fill: #FF5500;
}

@keyframes copybtn {
  0% {
    width: 1px;
    height: 1px;
    opacity: 1;
  }

  90% {
    width: 40px;
    height: 40px;
    opacity: 0;
  }

  100% {
    width: 1px;
    height: 1px;
    opacity: 0;
  }
}

@media screen and (min-width: 768px) {
  .checkbox:hover input:not[disabled] ~ div.mask,
  .radio:hover input:not[disabled] ~ div.mask {
    box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
  }
}

@media only screen and (max-width: 1160px), only screen and (max-device-width: 1160px) {
  .sec-welcome__content__box {
    max-width: 420px;
  }

  .sec-welcome__content__box h1 {
    font: 400 38px/110% 'BarlowC';
    margin-bottom: 8px;
  }

  .sec-welcome__content__box p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1050px), only screen and (max-device-width: 1050px) {
  html,
  body {
    font: 400 15px/125% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .text-box p,
  .text-wrap p {
    font: 400 15px/130% 'Montserrat', Helvetica, Arial, sans-serif;
  }
}

@media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
  .soc-services__content {
    padding-bottom: 64px;
  }

  .soc-services__content > [class*='item-'] {
    width: 100%;
    margin: 0 auto 32px;
    padding-top: 0;
    transform: translateY(0);
  }

  .soc-services__content > [class*='item-']:not(.item-center) {
    width: 50% !important;
  }

  .soc-services__content > .item-center {
    order: 0;
  }
}

@media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
  .text-box .sub-title > *,
  .text-wrap .sub-title > * {
    font: 600 28px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .accordion__theme-1 .accordion__item .accordion__item__header h3 {
    font: 600 20px/120% 'Montserrat', Helvetica, Arial, sans-serif !important;
  }

  .form_footer2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  .form_footer2 > * {
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 18px;
  }

  .form_footer2 > * > * {
    width: 100%;
  }

  .popup__title {
    font: 600 24px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .footer__copyright {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .title > * {
    font: 900 42px/120% 'BarlowC', Helvetica, Arial, sans-serif !important;
  }

  .title-2 > * {
    font: 900 22px/120% 'Montserrat', Helvetica, Arial, sans-serif !important;
  }

  .soc-services__content__item h3 {
    font: 600 20px/110% 'Montserrat', Helvetica, Arial, sans-serif !important;
  }
}

@media only screen and (max-width: 940px), only screen and (max-device-width: 940px) {
  .sec-about__content__illustration svg {
    width: 320px;
  }
}

@media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
  .resolution-wrap {
    padding-right: 16px;
    padding-left: 16px;
  }

  .text-box h1,
  .text-wrap h1 {
    font: 400 32px/105% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .text-box h2,
  .text-wrap h2 {
    font: 600 25px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .text-box h3,
  .text-wrap h3 {
    font: 600 20px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .text-box h4,
  .text-wrap h4 {
    font: 600 18px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .accordion__theme-1 .accordion__item {
    padding: 16px;
  }

  .cookie-banner {
    max-width: none;
  }
}

@media only screen and (max-width: 840px), only screen and (max-device-width: 840px) {
  .btn-font {
    font-size: 14px;
  }

  .btn-font::before {
    transform: scale(0.85) translateY(1px);
  }

  .btn__theme-1 {
    font-size: 14px;
  }

  .btn__theme-1::before {
    transform: scale(0.85) translateY(1px);
  }

  .btn__theme-2 {
    font-size: 14px;
  }

  .btn__theme-2::before {
    transform: scale(0.85) translateY(1px);
  }

  .btn__theme-3 {
    font-size: 14px;
  }

  .btn__theme-3::before {
    transform: scale(0.85) translateY(1px);
  }

  .btn__theme-5 {
    font-size: 14px;
  }

  .btn__theme-5::before {
    transform: scale(0.85) translateY(1px);
  }

  .style-btn__1 > * {
    font-size: 14px;
  }

  .style-btn__1 > *::before {
    transform: scale(0.85) translateY(1px);
  }

  .style-btn__1 > * svg {
    width: 30px;
    height: 30px;
  }

  .style-btn__2 > * {
    font-size: 14px;
  }

  .style-btn__2 > *::before {
    transform: scale(0.85) translateY(1px);
  }

  .style-btn__2 > * svg {
    width: 30px;
    height: 30px;
  }

  .style-btn__3 > * {
    font-size: 14px;
  }

  .style-btn__3 > *::before {
    transform: scale(0.85) translateY(1px);
  }

  .style-btn__3 > * svg {
    width: 30px;
    height: 30px;
  }

  .style-btn__5 > * {
    font-size: 14px;
  }

  .style-btn__5 > *::before {
    transform: scale(0.85) translateY(1px);
  }

  .style-btn__5 > * svg {
    width: 32px;
    height: 32px;
  }

  .form_style_1 button:not(.btn_search):not(.icn_btn):not(.btn_search2):not(.slick-arrow),
  .form_style_1 input[type="button"]:not(.btn_search):not(.btn_search2),
  .form_style_1 input[type="submit"]:not(.btn_search):not(.btn_search2),
  .form_style_1 input[type="reset"]:not(.btn_search):not(.btn_search2) {
    font-size: 14px;
  }

  .form_style_1 button:not(.btn_search):not(.icn_btn):not(.btn_search2):not(.slick-arrow)::before,
  .form_style_1 input[type="button"]:not(.btn_search):not(.btn_search2)::before,
  .form_style_1 input[type="submit"]:not(.btn_search):not(.btn_search2)::before,
  .form_style_1 input[type="reset"]:not(.btn_search):not(.btn_search2)::before {
    transform: scale(0.85) translateY(1px);
  }
}

@media only screen and (max-width: 820px), only screen and (max-device-width: 820px) {
  .sec-welcome .resolution-wrap {
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 120px;
  }

  .sec-welcome__content__box {
    text-align: center;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }

  .sec-welcome__content__box .style-btn__1 {
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .sec-welcome .bg-city .city {
    top: 100%;
    transform: translate(51%, -100%);
  }
}

@media only screen and (max-width: 780px), only screen and (max-device-width: 780px) {
  .text-box .row[data-columns='4'] > .column,
  .text-wrap .row[data-columns='4'] > .column,
  .text-box .logos[data-columns='4'] > .column,
  .text-wrap .logos[data-columns='4'] > .column {
    width: 50% !important;
  }
}

@media only screen and (max-width: 760px), only screen and (max-device-width: 760px) {
  .sec-about__content {
    flex-direction: column;
  }

  .sec-about__content__text {
    order: 2;
  }

  .sec-about__content__illustration {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 740px), only screen and (max-device-width: 740px) {
  .text-box table.adaptive-table tr,
  .text-wrap table.adaptive-table tr {
    grid-template-columns: 1fr auto auto;
  }

  .text-box table.adaptive-table tr > td:nth-child(1),
  .text-wrap table.adaptive-table tr > td:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(2),
  .text-wrap table.adaptive-table tr > td:nth-child(2) {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
  }

  .text-box table.adaptive-table tr > td:nth-child(3),
  .text-wrap table.adaptive-table tr > td:nth-child(3) {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: 2;
    grid-column-end: 4;
  }

  .text-box table.adaptive-table tr > td:nth-child(4),
  .text-wrap table.adaptive-table tr > td:nth-child(4) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(5),
  .text-wrap table.adaptive-table tr > td:nth-child(5) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(6),
  .text-wrap table.adaptive-table tr > td:nth-child(6) {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-column-end: 4;
  }

  .text-box table.adaptive-table tr > td:nth-child(7),
  .text-wrap table.adaptive-table tr > td:nth-child(7) {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 4;
  }

  .popup__container .popup__window {
    border-radius: 0;
    padding: 16px;
  }

  .popup__footer {
    flex-direction: column;
  }

  .popup__footer > * {
    margin-bottom: 16px;
    width: 100%;
  }

  .popup__footer > * > * {
    width: 100%;
  }
}

@media only screen and (max-width: 680px), only screen and (max-device-width: 680px) {
  .soc-services__content > [class*='item-'] {
    max-width: 480px;
  }

  .soc-services__content > [class*='item-']:not(.item-center) {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
  .resolution-text-wrap {
    padding: 0 23px;
  }

  .text-box .row[data-columns='3'] > .column,
  .text-wrap .row[data-columns='3'] > .column,
  .text-box .logos[data-columns='3'] > .column,
  .text-wrap .logos[data-columns='3'] > .column {
    width: 100% !important;
  }

  .text-box .sub-title > *,
  .text-wrap .sub-title > * {
    font: 600 20px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .accordion__theme-1 .accordion__item .accordion__item__header h3 {
    font: 600 18px/120% 'Montserrat', Helvetica, Arial, sans-serif !important;
  }

  .title > * {
    font: 900 36px/120% 'BarlowC', Helvetica, Arial, sans-serif !important;
  }

  .sec-hr-block__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .sec-hr-block__content__text {
    padding-right: 0;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 620px), only screen and (max-device-width: 620px) {
  .form__footer {
    flex-direction: column;
  }

  .form__footer > * {
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }

  .form__footer > * > * {
    width: 100% !important;
  }

  .forgot-password {
    margin-bottom: 6px;
    text-align: center;
  }

  .forgot-password > a {
    width: 100%;
    display: flex;
    text-align: center;
  }
}

@media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
  .text-box .row[data-columns='2'] > .column,
  .text-wrap .row[data-columns='2'] > .column,
  .text-box .logos[data-columns='2'] > .column,
  .text-wrap .logos[data-columns='2'] > .column {
    width: 100% !important;
  }

  .text-box .row[data-columns='4'] > .column,
  .text-wrap .row[data-columns='4'] > .column,
  .text-box .logos[data-columns='4'] > .column,
  .text-wrap .logos[data-columns='4'] > .column {
    width: 100% !important;
  }
}

@media only screen and (max-width: 560px), only screen and (max-device-width: 560px) {
  .text-box h1,
  .text-wrap h1 {
    font: 400 28px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .text-box h2,
  .text-wrap h2 {
    font: 600 20px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .text-box h3,
  .text-wrap h3 {
    font: 600 18px/120% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .text-box table.adaptive-table tr,
  .text-wrap table.adaptive-table tr {
    grid-template-columns: 1fr;
  }

  .text-box table.adaptive-table tr > td:nth-child(1),
  .text-wrap table.adaptive-table tr > td:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(2),
  .text-wrap table.adaptive-table tr > td:nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(3),
  .text-wrap table.adaptive-table tr > td:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(4),
  .text-wrap table.adaptive-table tr > td:nth-child(4) {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(5),
  .text-wrap table.adaptive-table tr > td:nth-child(5) {
    grid-row-start: 5;
    grid-column-start: 1;
    grid-row-end: 6;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(6),
  .text-wrap table.adaptive-table tr > td:nth-child(6) {
    grid-row-start: 6;
    grid-column-start: 1;
    grid-row-end: 7;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(7),
  .text-wrap table.adaptive-table tr > td:nth-child(7) {
    grid-row-start: 7;
    grid-column-start: 1;
    grid-row-end: 8;
    grid-column-end: 2;
  }
}

@media only screen and (max-width: 540px), only screen and (max-device-width: 540px) {
  .text-box .numeric-hn > span,
  .text-wrap .numeric-hn > span {
    font: 600 20px/120% 'Montserrat', Helvetica, Arial, sans-serif !important;
  }
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  #go-top {
    bottom: 85px;
  }
}

@media only screen and (max-width: 460px), only screen and (max-device-width: 460px) {
  .sec-welcome__content__box h1 {
    font: 400 30px/110% 'BarlowC';
  }

  .sec-welcome__content__box p {
    font-size: 16px;
  }
}

@media (min-width: 960px) and (max-width: 4480px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 90;
    width: calc(100% + 2px);
    max-width: 4200px;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    background: none;
  }

  .header .menu-burger {
    display: none;
  }

  .header .resolution-wrap {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    position: relative;
    transition: all 0.2s linear;
    padding-left: 16px;
    padding-right: 16px;
  }

  .header__main {
    height: 130px;
    max-height: 130px;
  }

  .header__main__nav {
    display: flex;
    margin-left: auto;
  }

  .header__main__nav ul {
    display: flex;
  }

  .header__main__nav ul li a {
    position: relative;
    color: #fff;
    text-decoration: none;
    font: 600 20px/110% 'Montserrat', Helvetica, Arial, sans-serif;
    display: flex;
    margin-left: 32px;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 60px;
  }

  .header__main__nav ul li a::after {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    transition: all 0.23s linear;
    bottom: 0;
    width: 1%;
    height: 3px;
    background: #FF5500;
    opacity: 0;
  }

  .header__main__nav ul li.active a::after {
    content: '';
    width: 100%;
    opacity: 1;
  }

  .header .logo {
    transition: all 0.3s linear;
    position: relative;
    margin-right: 32px;
  }

  .header .logo a {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.23s linear;
  }

  .header .logo a svg,
  .header .logo a img {
    width: 231px;
    height: calc(231px / 3.12);
    transition: all 0.23s linear;
  }

  .header.header__small {
    background: url("/images/bg-section-blue.jpg") 0 50%;
    background-size: cover !important;
  }

  .header.header__small .header__main {
    height: 60px;
  }

  .header.header__small .header__main__nav ul li a {
    font: 600 16px/110% 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .header.header__small .logo a svg,
  .header.header__small .logo a img {
    width: 120px;
    height: calc(120px / 3.12);
    transition: all 0.23s linear;
  }

  body.main .dynamic-padding {
    padding-top: 0;
  }

  body:not(.main) .dynamic-padding {
    padding-top: 170px;
  }

  body:not(.mobile) .header .menu__item a:not(.active):hover {
    color: #eb4100;
  }

  body:not(.mobile) .header .menu__item a:not(.active):hover::after {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    width: 120%;
    height: 20px;
    top: -36px;
    left: 50%;
    border-radius: 100%;
    transform: translateX(-50%);
    filter: blur(10px);
    background: #eb4100;
    transition: all 0.2s linear;
  }
}

@media (min-width: 280px) and (max-width: 959.98px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 90;
    width: calc(100% + 2px);
    max-width: 4200px;
    height: auto;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1px));
    background: none;
  }

  .header .menu-burger {
    display: flex;
    width: 46px;
    height: 46px;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);
  }

  .header .menu-burger:hover {
    cursor: pointer;
  }

  .header .menu-burger::before,
  .header .menu-burger i,
  .header .menu-burger::after {
    content: '';
    display: block;
    width: 60%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    transform-origin: 0 50%;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-out;
  }

  .header .menu-burger::before {
    top: 30%;
  }

  .header .menu-burger i {
    top: 50%;
  }

  .header .menu-burger::after {
    top: 70%;
  }

  .header .resolution-wrap {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    position: relative;
    transition: all 0.2s linear;
    padding-left: 16px;
    padding-right: 16px;
  }

  .header__main {
    height: 60px;
    max-height: 60px;
  }

  .header__main__nav {
    display: flex;
    min-width: 33.33%;
    margin-left: auto;
    position: absolute;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    background: rgba(16, 48, 96, 0.8);
    backdrop-filter: blur(8px);
    top: 0;
    right: 0;
    min-height: var(--app-height);
    padding-top: 80px;
    transition: all 0.2s linear;
  }

  .header__main__nav ul {
    display: flex;
    flex-direction: column;
  }

  .header__main__nav ul li {
    padding-left: 16px;
    padding-right: 16px;
  }

  .header__main__nav ul li a {
    position: relative;
    color: #fff;
    text-decoration: none;
    font: 600 20px/110% 'Montserrat', Helvetica, Arial, sans-serif;
    display: flex;
    padding: 16px;
  }

  .header__main__nav ul li a::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -19px;
    transition: all 0.23s linear;
    bottom: 4px;
    width: 10px;
    height: 10px;
    background: #FF5500;
    border-radius: 4px;
    scale: 0.5;
    opacity: 0;
  }

  .header__main__nav ul li.active a::after {
    content: '';
    scale: 1;
    opacity: 1;
  }

  .header .logo {
    transition: all 0.3s linear;
    position: relative;
    margin-right: 32px;
  }

  .header .logo a {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.23s linear;
  }

  .header .logo a svg,
  .header .logo a img {
    width: 120px;
    height: calc(120px / 3.12);
    transition: all 0.23s linear;
  }

  .header.header__small {
    background: url("/images/bg-section-blue.jpg") 0 50%;
    background-size: cover !important;
  }

  .header.menu-active .menu-burger::before {
    top: 50%;
    left: 20%;
    transform: rotate(40deg);
    width: 20px !important;
  }

  .header.menu-active .menu-burger i {
    left: 70%;
    opacity: 0;
  }

  .header.menu-active .menu-burger::after {
    top: 50%;
    left: 20%;
    transform: rotate(-40deg);
    width: 20px !important;
  }

  .header.menu-active .header__main__nav {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  body.main .dynamic-padding {
    padding-top: 0;
  }

  body:not(.main) .dynamic-padding {
    padding-top: 60px;
  }
}