// ---------------------------------- 404
.section__error {
  display: flex;
  min-height: calc(var(--app-height));
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  flex-direction: column;
  padding: @gap*2 @gap !important;
  background: url("@{img}bg-section-blue.jpg");
  background-size: cover !important;




  > * {
    position: relative;
    z-index: 3;
  }

  .section__error__number {
    font-size: 120px;
    color: @color_1;
    font-weight: @f_bold !important;
    line-height: 150%;
    display: flex;
    text-align: center;
  }

  .error__details {
    text-align: center;
    color: @color_w;
    margin-bottom: @gap *2;

    p{
      font: @f_sbold 22px/120% @font;
      letter-spacing: 0.05em;
    }
  }

}



