.cookie-banner {
  position: fixed;
  bottom: @gap;
  right: @gap;
  width: calc(100% - @gap * 2);
  max-width: 420px;

  @media only screen and (max-width: 860px),
  only screen and (max-device-width: 860px) {
    max-width:  none;
  }

  transform: translate(120%, 0);
  background-color: rgba(@color_w, 0.9);
  backdrop-filter: blur(3px);
  color: @color_b1;
  padding: @gap * 1.5;
  text-align: center;
  border-radius: @br;
  z-index: 1000;
  box-shadow: 0 4px 60px -3px rgba(@color_b1, 0.1), inset 0 1px 0 0 rgba(255,255,255,0.05);
  transition: all 0.23s linear;

  &.show{
    transform: translate(0, 0);
    opacity: 1;
  }

  header{
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    margin-bottom: @gap;

    svg{
      width: 18px;
      fill: @color_1;
      margin-right: 8px;
    }

    h4{
      font-size: 22px;
    }

    .cookie-banner p {
      font-size: 15px;
      margin: 0;
      width: 100%;
      display: inline-block;
    }

    .cookie-banner a {
      color: @color_1;
      text-decoration: underline;
    }

  }

  &__buttons{
    width: 100%;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    display: flex;
    gap: @gap;
  }
}

.close-cookie-popup-btn{
  display: none;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  width: 32px;
  height: 32px;
  position: absolute;
  top:0;
  right: 0;
  background: none;
  outline: none;
  border: 0 !important;

  svg{
    width: 16px;
    height: 16px;
    fill: @color_1 !important;
  }
}


.cookie-banner__buttons {
  margin-top: 16px;

  button{
    min-width: 110px;
    font-size: 15px;
  }
}

