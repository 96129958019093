//============================================================================
//++++++++++++++++++++++++++++++ POP-UP WINDOWS ++++++++++++++++++++++++++++++
//============================================================================
body::after{
  content: '';
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(@color_b1 - 20%, .8);
  position: fixed;
  top: 0;
  left: 0;
  .anim_on(.2s);
  opacity: 0;
  visibility: hidden;
}
body.popup__opened {

  &::after{
    opacity: 1;
    visibility: visible;
  }

  .main-container{
    filter: blur(1px);
  }
}

.header{
  transition: none;
}

.popup {
  position: fixed;
  .anim_on(.2s);
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  //background: rgba(@color_b1, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;

  &__container {
    width: 100%;
    min-height: 100%;
    padding: 30px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup__window {
      position: relative;
      width: 100%;
      max-width: 680px;
      padding: @gap*2;
      background: @color_w;
      //ackground: radial-gradient(37.79% 83.15% at 56.36% 46%, #2A152F 0%, #141313 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      border-radius: @br;
      .box-shadow(0 13px 60px -5px rgba(@color_b1 - 30%, 0.4));
      color: @color_b1;

      @media only screen and (max-width: 740px),
      only screen and (max-device-width: 740px) {
        border-radius: @br/2;
        padding: @gap;
      }

      .anim_on(@anim);
      transform: perspective(600) translateY(40%) rotateX(10deg);

      .popup__title {
        margin-bottom: 15px;
      }
      .popup__sub-title {
        margin-bottom: 15px;
      }
    }


    // POPUP CLOSE BTN
    .popup__close {
      text-decoration: none;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios


      .anim_on(0.2s);
      width: 32px;
      height: 32px;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 15;
      background: @color_1;
      border-radius: @br;
      .box-shadow(0 3px 20px -1px rgba(@color_1 - 30%, 0.4));

      &:hover {
        cursor: pointer;
        background: @color_4 + 20%;
      }

      &:active {
        transform: translateY(1px);
      }

      &::before, &::after {
        content: '';
        display: flex;
        top: 50%;
        left: 50%;
        background: @color_w;
        width: 3px;
        height: 50%;
        position: absolute;
        transform-origin: 50% 50%;
      }

      &::before{
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &::after{
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  &.popup__open {
    opacity: 1;
    visibility: visible;

    .popup__window {
      transform: perspective(600) translateY(0) rotateX(0);
    }
  }
}

.popup__big-icon{
  img, svg{
    pointer-events: none;
    width: 270px;
    fill: @color_1;
  }
}

// CUSTOM
.popup{

  &__title{
     font: @f_sbold 30px/120% @font;
     margin-bottom: 15px;

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      font: @f_sbold 24px/120% @font;
    }
  }

  &__text{
    margin-bottom: @gap*2;
  }

  &__big-icon{
    svg{
      width: 220px;
      height: 220px;
      fill: @color_1;
    }
  }

  &__footer{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    @media only screen and (max-width: 740px),
    only screen and (max-device-width: 740px) {
      flex-direction: column;

      > *{
        margin-bottom: @gap;
        width: 100%;

        > *{
          width: 100%;
        }
      }
    }
  }

  .checkbox{
    padding: 10px 0;
  }

}




