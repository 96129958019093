.accordion {
  display: flex;
  flex-direction: column;

  &__item {
    margin-bottom: @gap;

    &__header {
      display: flex;
      min-height: 50px;
      //border: 1px solid red;
      align-items: center;
      align-content: center;
      position: relative;

      &::after {
        content: "\e035"; //plus
        .font-icon;
        position: absolute;
        right: 0;
        top: 14px;
        font-size: 22px;
        color: rgba(@color_b3, .5);
      }
    }

    &__content {
      transition: all 0.1s ease-in-out;
      transform-origin: 50% 0;
      overflow: hidden;
    }

    &:not(.active) {
      .accordion__item__content {
        max-height: 0;
        transform: scaleY(.3);
        opacity: 0;
        visibility: hidden;
      }
    }

    &.active {
      .accordion__item__header {
        &::after {
          content: "\e024"; //minus
          .font-icon;
          color: rgba(@color_1, 1);
        }
      }

      .accordion__item__content {
        max-height: 1000px;
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}


.accordion__theme-1 {
  .accordion__item {
    padding: @gap @gap * 2;
    border-radius: @br;
    background: @color_w;
    box-shadow: inset 0 0 0 2px rgba(@color_b5, 1);
    
    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      padding: @gap;
    }
         

    .accordion__item__header {
      padding-right: 40px;
      cursor: pointer;

      h3{
        margin-bottom: 2px;
        font: @f_sbold 22px/120% @font !important;

        @media only screen and (max-width: 960px),
        only screen and (max-device-width: 960px) {
          font: @f_sbold 20px/120% @font !important;
        }
        @media only screen and (max-width: 640px),
        only screen and (max-device-width: 640px) {
          font: @f_sbold 18px/120% @font !important;
        }
      }
    }

    .accordion__item__content {
      p {
        margin-bottom: 16px;
      }
    }

    &.active {
      box-shadow: inset 0 0 0 2px rgba(@color_1, 1);
    }
  }
}