.footer {
  width: 100%;
  position: relative;
  z-index: 3;
  background: @color_4;
  color: @color_w;

  &__copyright {
    padding: @gap;
    position: relative;
    font-size: 14px;
    z-index: 4;
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      padding-top: @gap;
      padding-bottom: @gap;
    }
  }
}
